import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PreloaderComponent } from './components/layouts/preloader/preloader.component';
import { NavbarComponent } from './components/layouts/navbar/navbar.component';
import { SidebarComponent } from './components/layouts/sidebar/sidebar.component';
import { FooterComponent } from './components/layouts/footer/footer.component';
import { HomeComponent } from './components/pages/home/home.component';
import { MarketingAgencyComponent } from './components/pages/marketing-agency/marketing-agency.component';
import { DigitalMarketingComponent } from './components/pages/digital-marketing/digital-marketing.component';
import { BusinessSolutionsComponent } from './components/pages/business-solutions/business-solutions.component';
import { AboutComponent } from './components/pages/about/about.component';
import { SoftwareDevelopmentComponent } from './components/pages/software-development/software-development.component';
import { DataScienceComponent } from './components/pages/data-science/data-science.component';
import { CloudEngineeringComponent } from './components/pages/cloud-engineering/cloud-engineering.component';
import { ProductPrototypingComponent } from './components/pages/product-prototyping/product-prototyping.component';
import { ServicesComponent } from './components/pages/services/services.component';
import { ServiceDetailsComponent } from './components/pages/service-details/service-details.component';
import { ProjectsComponent } from './components/pages/projects/projects.component';
import { ProjectMLManufacturingComponent } from './components/pages/project-ml-manufacturing/project-ml-manufacturing.component';
import { ProjectDemandForecastComponent } from './components/pages/project-demand-forecast/project-demand-forecast.component';
import { ProjectDetailsComponent } from './components/pages/project-details/project-details.component';
import { ProjectTelcoComponent } from './components/pages/project-telco/project-telco.component';
import { BlogComponent } from './components/pages/blog/blog.component';
import { BlogDetailsComponent } from './components/pages/blog-details/blog-details.component';
import { FeaturesComponent } from './components/pages/features/features.component';
import { TeamComponent } from './components/pages/team/team.component';
import { PricingComponent } from './components/pages/pricing/pricing.component';
import { PartnerComponent } from './components/pages/partner/partner.component';
import { NotFoundComponent } from './components/pages/not-found/not-found.component';
import { FaqComponent } from './components/pages/faq/faq.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ContactService } from './contact.service';
import { FormsModule }   from '@angular/forms';

@NgModule({
  declarations: [
    AppComponent,
    PreloaderComponent,
    NavbarComponent,
    SidebarComponent,
    FooterComponent,
    HomeComponent,
    MarketingAgencyComponent,
    DigitalMarketingComponent,
    BusinessSolutionsComponent,
    AboutComponent,
    SoftwareDevelopmentComponent,
    DataScienceComponent,
    CloudEngineeringComponent,
    ProductPrototypingComponent,
    ServicesComponent,
    ServiceDetailsComponent,
    ProjectsComponent,
    ProjectDetailsComponent,
    ProjectDemandForecastComponent,
    ProjectMLManufacturingComponent,
    ProjectTelcoComponent,
    BlogComponent,
    BlogDetailsComponent,
    FeaturesComponent,
    TeamComponent,
    PricingComponent,
    PartnerComponent,
    NotFoundComponent,
    FaqComponent,
    ContactComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    FormsModule
  ],
  providers: [ContactService],
  bootstrap: [AppComponent]
})
export class AppModule { }
