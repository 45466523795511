<!-- Start Navbar Area -->
<div class="navbar-area">
    <div class="aronix-nav">
        <div class="container">
            <nav class="navbar navbar-expand-md navbar-light">
                <a class="navbar-brand" routerLink="/">
                    <img src="assets/img/logo.png" alt="logo">
                </a>

                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>

                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav">
                        <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                            <a routerLink="/" routerLinkActive="active" class="nav-link">
                                Home
                            </a>
                        </li>

                        <!--  ngbDropdown (openChange)="toggled($event)" -->
                        <li class="nav-item dropdown">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle" data-toggle="dropdown">
                                Services <i class="fas fa-chevron-down"></i>
                            </a>
                            <ul class="dropdown-menu">
                                <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                                    <a routerLink="/product-prototyping" routerLinkActive="active" class="nav-link">
                                        Product Prototyping
                                    </a>
                                </li>
        
                                <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                                    <a routerLink="/software-development" routerLinkActive="active" class="nav-link">
                                        Software Development
                                    </a>
                                </li>
        
                                <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                                    <a routerLink="/data-science" routerLinkActive="active" class="nav-link">
                                        Data Science
                                    </a>
                                </li>
        
                                <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                                    <a routerLink="/cloud-engineering" routerLinkActive="active" class="nav-link">
                                        Cloud Engineering
                                    </a>
                                </li>
                            </ul>
                        </li>

                        <li class="nav-item dropdown">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle" data-toggle="dropdown">
                                Projects <i class="fas fa-chevron-down"></i>
                            </a>
                            <ul class="dropdown-menu">
                                <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                                    <a routerLink="/project-ml-manufacturing" routerLinkActive="active" class="nav-link">
                                        Manufacturing AI
                                    </a>
                                </li>
        
                                <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                                    <a routerLink="/project-demand-forecast" routerLinkActive="active" class="nav-link">
                                        Demand Forecast
                                    </a>
                                </li>
        
                                <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                                    <a routerLink="/project-telco" routerLinkActive="active" class="nav-link">
                                        Telco
                                    </a>
                                </li>
                            </ul>
                        </li>

                        <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                            <a routerLink="/team" routerLinkActive="active" class="nav-link">
                                Team
                            </a>
                        </li>

                        <li class="nav-item">
                            <a routerLink="/contact" routerLinkActive="active" class="nav-link">
                                Contacts
                            </a>
                        </li>

                        <!-- <li class="nav-item">
                            <a routerLink="/about" routerLinkActive="active" class="nav-link">About</a>
                        </li>

                        <li class="nav-item">
                            <a routerLink="/services" routerLinkActive="active" class="nav-link">
                                Services
                            </a>
                        </li>

                        <li class="nav-item">
                            <a routerLink="/projects" routerLinkActive="active" class="nav-link">
                                Projects
                            </a>
                        </li>

                        <li class="nav-item">
                            <a routerLink="/blog" routerLinkActive="active" class="nav-link">
                                Blog
                            </a>
                        </li> -->
                    </ul>

                    <div class="others-options">
                        <!--
                        <div class="option-item"><i class="search-btn flaticon-search"></i>
                            <i class="close-btn flaticon-close"></i>

                            
                            <div class="search-overlay search-popup">
                                <div class='search-box'>
                                    <form class="search-form">
                                        <input class="search-input" name="search" placeholder="Search" type="text">

                                        <button class="search-button" type="submit"><i
                                                class="fas fa-search"></i></button>
                                    </form>
                                </div>
                            </div>
                           
                        </div>
                        -->

                        <!-- <div class="burger-menu">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div> -->
                    </div>
                </div>
            </nav>
        </div>
    </div>
</div>
<!-- End Navbar Area -->
