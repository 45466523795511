<!-- Start Main Banner Area -->
<div class="hero-banner services">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-12">
                        <div class="hero-banner-content">
                            <h1>Cloud Engineering</h1>
                            <a href="javascript:void(Tawk_API.popup())" class="default-btn">Get Estimate<span></span></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Main Banner Area -->

<!-- Start Details Section Area -->
<div class="details-section-area">
    <div class="container-fluid">
        <div class="row dark-bg">
            <div class="row-padding">
                <div class="col-12 col-md-7 img-holder">
                    <div class="section-area-img img1">
                    </div>
                </div>
                <div class="col-12 col-md-5 text-holder">
                    <div class="section-area-text">
                        <div class="details-title">Cloud Infrastructure</div>
                        <div class="details-text">
                            <div class="mb-25">
                            Storing apps on the cloud is the new standard as it delivers safer and faster solutions. We
                            operate on all of the top 3 giants for cloud services - GCP, AWS and Azure. Our devops are
                            Google Certified Cloud Architects.
                            </div>
                            <div class="mb-25">
                                <i class="flaticon-tick"></i>
                                Infrastructure As A Code (IAAC) with Terraform
                            </div>
                            <div class="mb-25">
                                <i class="flaticon-tick"></i>
                                Configuration Management with Ansible
                            </div>
                            <div class="mb-25">
                                <i class="flaticon-tick"></i>
                                Hybrid and Multi clouds
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row gray-bg">
            <div class="row-padding">
                <div class="col-12 col-md-5 text-holder">
                    <div class="section-area-text">
                        <div class="details-title">Continuous Delivery</div>
                        <div class="details-text">
                            <div class="mb-25">
                            Continuous Integration and Continuous Delivery is our approach for every project to automate
                            the infrastructure. We use Jenkins and CodeFresh for creating CI/CD pipelines. The result is a
                            service that is:
                            </div>
                            <div class="mb-25">
                                <i class="flaticon-tick"></i>
                                Secure
                            </div>
                            <div class="mb-25">
                                <i class="flaticon-tick"></i>
                                Flexible
                            </div>
                            <div class="mb-25">
                                <i class="flaticon-tick"></i>
                                Scalable
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-7 img-holder">
                    <div class="section-area-img img2">
                    </div>
                </div>
            </div>
        </div>
        <div class="row dark-bg">
            <div class="row-padding">
                <div class="col-12 col-md-7 img-holder">
                    <div class="section-area-img img3">
                    </div>
                </div>
                <div class="col-12 col-md-5 text-holder">
                    <div class="section-area-text">
                        <div class="details-title">Conteiners</div>
                        <div class="details-text">
                            <div class="mb-25">
                            Packaging apps and their specific resources so they can be available on different hosts. To
                            do that, we use the best tools out there - Docker for creating containers and Kubernetes for
                            orchestrating them.
                            </div>
                            <div class="mb-25">
                                <i class="flaticon-tick"></i>
                                Both centralized and distributed containers
                            </div>
                            <div class="mb-25">
                                <i class="flaticon-tick"></i>
                                Performance Management
                            </div>
                            <div class="mb-25">
                                <i class="flaticon-tick"></i>
                                Containers Security
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Details Section Area -->

<div class="partner-area ptb-100">
    <div class="container">
        <div class="partner-slides owl-carousel owl-theme">
            <div class="single-partner-item">
                <a href="#">
                    <img src="assets/img/partner-image/docker.svg" alt="image" title="Docker">
                    <img src="assets/img/partner-image/docker.svg" alt="image" title="Docker">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#">
                    <img src="assets/img/partner-image/kubernetes.png" alt="image" title="Kubernetes">
                    <img src="assets/img/partner-image/kubernetes.png" alt="image" title="Kubernetes">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#">
                    <img src="assets/img/partner-image/terraform.svg" alt="image" title="Terraform">
                    <img src="assets/img/partner-image/terraform.svg" alt="image" title="Terraform">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#">
                    <img src="assets/img/partner-image/ansible.png" alt="image" title="Ansible">
                    <img src="assets/img/partner-image/ansible.png" alt="image" title="Ansible">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#">
                    <img src="assets/img/partner-image/jenkins.svg" alt="image" title="Jenkins">
                    <img src="assets/img/partner-image/jenkins.svg" alt="image" title="Jenkins">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#">
                    <img src="assets/img/partner-image/codefressh.png" alt="image" title="CodeFresh">
                    <img src="assets/img/partner-image/codefressh.png" alt="image" title="CodeFresh">
                </a>
            </div>
        </div>
    </div>
</div>