<!-- Start Main Banner Area -->
<div class="hero-banner services">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-12">
                        <div class="hero-banner-content">
                            <h1>Project Telco</h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Main Banner Area -->

<!-- Start Details Section Area -->
<div class="details-section-area">
    <div class="container-fluid">
        <div class="row dark-bg">
            <div class="row-padding">
                <div class="col-12 col-md-7 img-holder">
                    <div class="section-area-img img1">
                    </div>
                </div>
                <div class="col-12 col-md-5 text-holder">
                    <div class="section-area-text">
                        <div class="details-title">Telecom Case</div>
                        <div class="details-text">
                            <div class="mb-25">
                                The telecommunication company had a well-established ITSM software with a traditional architecture and were 
                                looking for an agile solution, using next generation data-driven and 
                                microservice-oriented architecture. Telco (a telecom provider) had a heavy IT service 
                                infrastructure, subject to ITIL methodology and used the current software to manage 
                                it. We've investigated their code and found how applying Machine Learning will 
                                lower the errors' occurrence and save the company resources. The 2 new features were 
                                Real-time log entity ingestion for anomaly detection (AD) and Identifying related incidents 
                                and root causes (RCA).
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row gray-bg">
            <div class="row-padding">
                <div class="col-12 col-md-5 text-holder">
                    <div class="section-area-text">
                        <div class="details-title">How we did it</div>
                        <div class="details-text">
                            <div class="mb-25">
                                We completed the project, following a couple of milestones. We started off by making a 
                                holistic research about AD and RCA where we evaluated in more detail the current situation. 
                                Then we used the existing configuration and structured data to create knowledge graphs. 
                                And the biggest step was writing the code - at the end we have built a container-component 
                                dependency graph, used for solving RCA from AD. We followed a few AI approaches in order 
                                to complete the coding part of the project. We took advantage of Supervised Machine 
                                Learning algorithms to train a statistical model.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-7 img-holder">
                    <div class="section-area-img img2">
                    </div>
                </div>
            </div>
        </div>
        <div class="row dark-bg">
            <div class="row-padding">
                <div class="col-12 col-md-7 img-holder">
                    <div class="section-area-img img3">
                    </div>
                </div>
                <div class="col-12 col-md-5 text-holder">
                    <div class="section-area-text">
                        <div class="details-title">Technical point of view</div>
                        <div class="details-text">
                            <div class="mb-25">
                                The algorithms we used were Support 
                                Vector Machines, Bayesian Networks, Maximum Entropy, Conditional Random 
                                Field and Neural Networks/Deep Learning. These algorithms managed to find 
                                patterns amongst the data, analyzed them and managed to make predictions of when an anomaly 
                                will most likely occur. We have also applied Natural Language Processing (NLP). We collected 
                                large amounts of log events related to systems and then transformed the logs into 
                                multidimensional vectors of features and trained a classifier on the resulting data. 
                                Then, using a standard classifier, we predicted the outcome - the next log event. 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Details Section Area -->

<div class="partner-area ptb-100">
    <div class="container">
        <div class="partner-slides owl-carousel owl-theme">
            <div class="single-partner-item">
                <a href="#">
                    <img src="assets/img/partner-image/vuejs.png" alt="image">
                    <img src="assets/img/partner-image/vuejs.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#">
                    <img src="assets/img/partner-image/react.png" alt="image">
                    <img src="assets/img/partner-image/react.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#">
                    <img src="assets/img/partner-image/angular.png" alt="image">
                    <img src="assets/img/partner-image/angular.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#">
                    <img src="assets/img/partner-image/python.png" alt="image">
                    <img src="assets/img/partner-image/python.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#">
                    <img src="assets/img/partner-image/mysql.png" alt="image">
                    <img src="assets/img/partner-image/mysql.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#">
                    <img src="assets/img/partner-image/nodejs.png" alt="image">
                    <img src="assets/img/partner-image/nodejs.png" alt="image">
                </a>
            </div>
        </div>
    </div>
</div>