<!-- Start Page Title Area -->
<div class="page-title-area page-title-bg2">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Services</h2>

                    <ul>
                        <li><a href="#">Home</a></li>
                        <li>Services</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="shape-img2"><img src="assets/img/shape/2.svg" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/3.svg" alt="image"></div>
    <div class="shape-img4"><img src="assets/img/shape/4.png" alt="image"></div>
    <div class="shape-img5"><img src="assets/img/shape/5.png" alt="image"></div>
    <div class="shape-img7"><img src="assets/img/shape/7.png" alt="image"></div>
    <div class="shape-img8"><img src="assets/img/shape/8.png" alt="image"></div>
    <div class="shape-img9"><img src="assets/img/shape/9.png" alt="image"></div>
    <div class="shape-img10"><img src="assets/img/shape/10.png" alt="image"></div>
</div>
<!-- End Page Title Area -->

<!-- Start Why Choose Us Area -->
<section class="why-choose-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="why-choose-content">
                    <span class="sub-title">Why Choose Us</span>
                    <h2>The Key To Your Motivation And Success</h2>
                    <p>We believe brand interaction is key in communication. Real innovations and a positive customer
                        experience are the heart of successful communication.</p>

                    <div class="features-text">
                        <h4><i class="flaticon-tick"></i> Core Development</h4>
                        <p>No fake products and services. The customer is king, their lives and needs are the
                            inspiration.</p>
                    </div>

                    <div class="features-text">
                        <h4><i class="flaticon-tick"></i> Define Your Choices</h4>
                        <p>No fake products and services. The customer is king, their lives and needs are the
                            inspiration.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="why-choose-image">
                    <img src="assets/img/why-choose-img1.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Why Choose Us Area -->

<!-- Start Services Area -->
<section class="services-area ptb-100 bg-F4F7FC">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">What We Do</span>
            <h2>Provide Awesome Service With Our Tools</h2>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-digital-marketing"></i>
                    </div>

                    <h3><a href="#">Digital Marketing</a></h3>

                    <p>Lorem ipsum dolor sit amet elit sed, consectetur adipiscing elit, sed do eiusmod tempor
                        incididunt.</p>
                    <a href="#" class="read-more-btn">Read More <i class="flaticon-right-arrow"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon bg-00aeff">
                        <i class="flaticon-research"></i>
                    </div>

                    <h3><a href="#">Design & Development</a></h3>

                    <p>Lorem ipsum dolor sit amet elit sed, consectetur adipiscing elit, sed do eiusmod tempor
                        incididunt.</p>
                    <a href="#" class="read-more-btn">Read More <i class="flaticon-right-arrow"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon bg-f78acb">
                        <i class="flaticon-analytics"></i>
                    </div>

                    <h3><a href="#">Strategic Planing</a></h3>

                    <p>Lorem ipsum dolor sit amet elit sed, consectetur adipiscing elit, sed do eiusmod tempor
                        incididunt.</p>
                    <a href="#" class="read-more-btn">Read More <i class="flaticon-right-arrow"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon bg-cdf1d8">
                        <i class="flaticon-analysis"></i>
                    </div>

                    <h3><a href="#">SEO Consultancy</a></h3>

                    <p>Lorem ipsum dolor sit amet elit sed, consectetur adipiscing elit, sed do eiusmod tempor
                        incididunt.</p>
                    <a href="#" class="read-more-btn">Read More <i class="flaticon-right-arrow"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon bg-c679e3">
                        <i class="flaticon-mail"></i>
                    </div>

                    <h3><a href="#">Competitor Analysis</a></h3>

                    <p>Lorem ipsum dolor sit amet elit sed, consectetur adipiscing elit, sed do eiusmod tempor
                        incididunt.</p>
                    <a href="#" class="read-more-btn">Read More <i class="flaticon-right-arrow"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon bg-eb6b3d">
                        <i class="flaticon-seo"></i>
                    </div>

                    <h3><a href="#">Social Media Marketing</a></h3>

                    <p>Lorem ipsum dolor sit amet elit sed, consectetur adipiscing elit, sed do eiusmod tempor
                        incididunt.</p>
                    <a href="#" class="read-more-btn">Read More <i class="flaticon-right-arrow"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-analytics"></i>
                    </div>

                    <h3><a href="#">Marketing Analysis</a></h3>

                    <p>Lorem ipsum dolor sit amet elit sed, consectetur adipiscing elit, sed do eiusmod tempor
                        incididunt.</p>
                    <a href="#" class="read-more-btn">Read More <i class="flaticon-right-arrow"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon bg-c679e3">
                        <i class="flaticon-mail"></i>
                    </div>

                    <h3><a href="#">Email Marketing</a></h3>

                    <p>Lorem ipsum dolor sit amet elit sed, consectetur adipiscing elit, sed do eiusmod tempor
                        incididunt.</p>
                    <a href="#" class="read-more-btn">Read More <i class="flaticon-right-arrow"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon bg-cdf1d8">
                        <i class="flaticon-mail"></i>
                    </div>

                    <h3><a href="#">Website Optimization</a></h3>

                    <p>Lorem ipsum dolor sit amet elit sed, consectetur adipiscing elit, sed do eiusmod tempor
                        incididunt.</p>
                    <a href="#" class="read-more-btn">Read More <i class="flaticon-right-arrow"></i></a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Services Area -->
