<!-- Start Page Title Area -->
<div class="page-title-area page-title-bg1">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Partner</h2>

                    <ul>
                        <li><a href="#">Home</a></li>
                        <li>Partner</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="shape-img2"><img src="assets/img/shape/2.svg" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/3.svg" alt="image"></div>
    <div class="shape-img4"><img src="assets/img/shape/4.png" alt="image"></div>
    <div class="shape-img5"><img src="assets/img/shape/5.png" alt="image"></div>
    <div class="shape-img7"><img src="assets/img/shape/7.png" alt="image"></div>
    <div class="shape-img8"><img src="assets/img/shape/8.png" alt="image"></div>
    <div class="shape-img9"><img src="assets/img/shape/9.png" alt="image"></div>
    <div class="shape-img10"><img src="assets/img/shape/10.png" alt="image"></div>
</div>
<!-- End Page Title Area -->

<!-- Start Partner Area -->
<section class="partner-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="single-partner-item">
                <a href="#">
                    <img src="assets/img/partner-image/1.png" alt="image">
                    <img src="assets/img/partner-image/1.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#">
                    <img src="assets/img/partner-image/2.png" alt="image">
                    <img src="assets/img/partner-image/2.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#">
                    <img src="assets/img/partner-image/3.png" alt="image">
                    <img src="assets/img/partner-image/3.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#">
                    <img src="assets/img/partner-image/4.png" alt="image">
                    <img src="assets/img/partner-image/4.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#">
                    <img src="assets/img/partner-image/5.png" alt="image">
                    <img src="assets/img/partner-image/5.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#">
                    <img src="assets/img/partner-image/6.png" alt="image">
                    <img src="assets/img/partner-image/6.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#">
                    <img src="assets/img/partner-image/7.png" alt="image">
                    <img src="assets/img/partner-image/7.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#">
                    <img src="assets/img/partner-image/8.png" alt="image">
                    <img src="assets/img/partner-image/8.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#">
                    <img src="assets/img/partner-image/9.png" alt="image">
                    <img src="assets/img/partner-image/9.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#">
                    <img src="assets/img/partner-image/10.png" alt="image">
                    <img src="assets/img/partner-image/10.png" alt="image">
                </a>
            </div>
        </div>
    </div>

    <div class="shape-img2"><img src="assets/img/shape/2.svg" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/3.svg" alt="image"></div>
    <div class="shape-img4"><img src="assets/img/shape/4.png" alt="image"></div>
    <div class="shape-img5"><img src="assets/img/shape/5.png" alt="image"></div>
</section>
<!-- End Partner Area -->
