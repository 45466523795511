import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
  
  scrollTo($element): void {
    $element.scrollIntoView(true);

    // offset setup because of fixed header
    let menuHeight = 150;
    let scrolledY = window.scrollY;
    if(scrolledY){
      window.scroll(0, scrolledY - menuHeight);
    }
  }
}