<!-- Start Footer Area -->
<section class="footer-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="single-footer-widget">
                    <h3>Contact Info</h3>

                    <ul class="footer-contact-info">
                        <li>
                            <i class="flaticon-phone-call"></i>
                            <span>Contact us on:</span>
                            <a href="#">+359 883 472 597</a>
                        </li>
                        <li>
                            <i class="flaticon-email"></i>
                            <span>Do You Have a Question?</span>
                            <a href="#">office@abovedev.com</a>
                        </li>
                        <!-- <li>
                            <i class="flaticon-social-media"></i>
                            <span>Socials Networks</span>

                            <ul class="social">
                                <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                                <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                                <li><a href="#"><i class="fab fa-instagram"></i></a></li>
                                <li><a href="#"><i class="fab fa-linkedin"></i></a></li>
                            </ul>
                        </li> -->
                    </ul>
                </div>
            </div>

            <!-- <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="single-footer-widget pl-5">
                    <h3>Quick Links</h3>

                    <ul class="footer-quick-links">
                        <li><a href="#">Home</a></li>
                        <li><a href="#">About</a></li>
                        <li><a href="#">Blog</a></li>
                        <li><a href="#">Contact</a></li>
                        <li><a href="#">Agency</a></li>
                        <li><a href="#">Digital</a></li>
                        <li><a href="#">Support</a></li>
                        <li><a href="#">Shop</a></li>
                        <li><a href="#">Agency</a></li>
                        <li><a href="#">Digital</a></li>
                        <li><a href="#">Support</a></li>
                        <li><a href="#">Shop</a></li>
                        <li><a href="#">Digital</a></li>
                        <li><a href="#">Support</a></li>
                    </ul>
                </div>
            </div> -->

            <!-- <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                <div class="single-footer-widget pl-5">
                    <h3>Instagram</h3>

                    <ul class="footer-instagram-post">
                        <li><a href="#">
                                <img src="assets/img/instagram-image/1.jpg" alt="image">
                            </a></li>
                        <li><a href="#">
                                <img src="assets/img/instagram-image/2.jpg" alt="image">
                            </a></li>
                        <li><a href="#">
                                <img src="assets/img/instagram-image/3.jpg" alt="image">
                            </a></li>
                        <li><a href="#">
                                <img src="assets/img/instagram-image/4.jpg" alt="image">
                            </a></li>
                        <li><a href="#">
                                <img src="assets/img/instagram-image/5.jpg" alt="image">
                            </a></li>
                        <li><a href="#">
                                <img src="assets/img/instagram-image/6.jpg" alt="image">
                            </a></li>
                        <li><a href="#">
                                <img src="assets/img/instagram-image/7.jpg" alt="image">
                            </a></li>
                        <li><a href="#">
                                <img src="assets/img/instagram-image/8.jpg" alt="image">
                            </a></li>
                        <li><a href="#">
                                <img src="assets/img/instagram-image/1.jpg" alt="image">
                            </a></li>
                    </ul>
                </div>
            </div> -->
        </div>

        <div class="copyright-area">
            <div class="row align-items-center">
                <div class="col-lg-6 col-sm-6 col-md-6">
                    <p> &copy; AboveDev {{currentYear}}. All rights reserved</p>
                </div>

                <div class="col-lg-6 col-sm-6 col-md-6">
                    <ul>
                        <li><a href="#">Terms & Conditions</a></li>
                        <li><a href="#">Privacy Policy</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Footer Area -->

<div class="go-top"><i class="fas fa-chevron-up"></i></div>