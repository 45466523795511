<!-- Start Page Title Area -->
<div class="page-title-area page-title-bg2">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Projects</h2>

                    <ul>
                        <li><a href="#">Home</a></li>
                        <li>Projects</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="shape-img2"><img src="assets/img/shape/2.svg" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/3.svg" alt="image"></div>
    <div class="shape-img4"><img src="assets/img/shape/4.png" alt="image"></div>
    <div class="shape-img5"><img src="assets/img/shape/5.png" alt="image"></div>
    <div class="shape-img7"><img src="assets/img/shape/7.png" alt="image"></div>
    <div class="shape-img8"><img src="assets/img/shape/8.png" alt="image"></div>
    <div class="shape-img9"><img src="assets/img/shape/9.png" alt="image"></div>
    <div class="shape-img10"><img src="assets/img/shape/10.png" alt="image"></div>
</div>
<!-- End Page Title Area -->

<!-- Start Projects Area -->
<section class="projects-area ptb-100">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-projects-box">
                    <img src="assets/img/projects-image/1.jpg" alt="image">

                    <div class="projects-content">
                        <h3><a href="#">Digital Marketing</a></h3>
                        <span class="category">Design</span>
                    </div>

                    <div class="plus-icon">
                        <a href="assets/img/projects-image/1.jpg" class="popup-btn">
                            <span></span>
                        </a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-projects-box">
                    <img src="assets/img/projects-image/2.jpg" alt="image">

                    <div class="projects-content">
                        <h3><a href="#">Design & Development</a></h3>
                        <span class="category">Planing</span>
                    </div>

                    <div class="plus-icon">
                        <a href="assets/img/projects-image/2.jpg" class="popup-btn">
                            <span></span>
                        </a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-projects-box">
                    <img src="assets/img/projects-image/3.jpg" alt="image">

                    <div class="projects-content">
                        <h3><a href="#">Strategic Planing</a></h3>
                        <span class="category">Marketing</span>
                    </div>

                    <div class="plus-icon">
                        <a href="assets/img/projects-image/3.jpg" class="popup-btn">
                            <span></span>
                        </a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-projects-box">
                    <img src="assets/img/projects-image/4.jpg" alt="image">

                    <div class="projects-content">
                        <h3><a href="#">SEO Consultancy</a></h3>
                        <span class="category">Development</span>
                    </div>

                    <div class="plus-icon">
                        <a href="assets/img/projects-image/4.jpg" class="popup-btn">
                            <span></span>
                        </a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-projects-box">
                    <img src="assets/img/projects-image/5.jpg" alt="image">

                    <div class="projects-content">
                        <h3><a href="#">Competitor Analysis</a></h3>
                        <span class="category">Design</span>
                    </div>

                    <div class="plus-icon">
                        <a href="assets/img/projects-image/5.jpg" class="popup-btn">
                            <span></span>
                        </a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-projects-box">
                    <img src="assets/img/projects-image/6.jpg" alt="image">

                    <div class="projects-content">
                        <h3><a href="#">Social Media Marketing</a></h3>
                        <span class="category">Development</span>
                    </div>

                    <div class="plus-icon">
                        <a href="assets/img/projects-image/6.jpg" class="popup-btn">
                            <span></span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Projects Area -->
