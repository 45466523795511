<!-- Start Main Banner Area -->
<div class="hero-banner banner-bg2">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12 m-auto text-center">
                        <div class="hero-main-banner-content">
                            <h1>Code Above Limits!</h1>
                            <a routerLink='/contact' routerLinkActive="active" class="default-btn">
                                <span></span>
                                Get Estimate
                            </a>
                            <!-- <a href="javascript:void(Tawk_API.popup())" class="default-btn">Chat with us<span></span></a> -->
                            <!-- href="javascript:void(Tawk_API.toggle())" -->
                            <!-- href="https://tawk.to/chat/6027dcd5918aa261273e91ce/1eudrd9de" target="_blank" -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Main Banner Area -->

<!-- Start Featured Services Area -->
<section class="featured-services-area ptb-100 pt-0">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Services</span>
            <h2>Full Stack Engineering</h2>
            <p></p>
        </div>

        <div class="row">
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-featured-box">
                    <img src="assets/img/product-prototyping-icon.svg">
                    <h3>Product Prototyping</h3>
                    <p>
                        <!-- Turning the ideas in your head into a visual digitalised design!  -->
                        We help you create your minimum viable product efectivelly in a glance. Get your product started 
                        with well defined UX, functions and branding.
                    </p>
                    <a (click)=scrollTo(productPrototyping) class="default-btn">See More <span></span></a>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-featured-box">
                    <img src="assets/img/software-development-icon.svg">
                    <h3>Software Development</h3>
                    <p>
                        <!-- Solving IT problems and developing solutions from A to Z! -->
                        Nowadays digitalization is crucial for business survival. Communicate, Design, Develop, Test and 
                        Deploy maintainable code projects with our support.
                    </p>
                    <a (click)=scrollTo(softwareDevelopment) class="default-btn">See More <span></span></a>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-featured-box">
                    <img src="assets/img/data_science.svg">
                    <h3>Data <br>Science</h3>
                    <p>
                        <!-- Mathematics is in our blood and we love machine learning! -->
                        Extracting knowledge from data, feeding AI and model statistics is our passion. 
                        We are a small but smart team with Math background and Python knowledge.
                    </p>
                    <a (click)=scrollTo(dataScience) class="default-btn">See More <span></span></a>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-featured-box">
                    <img src="assets/img/cloud-engineering-icon.svg">
                    <h3>Cloud <br>Engineering</h3>
                    <p>
                        <!-- Managing and automating your journey <br>to the cloud! -->
                        Maintaining deployments for the past 20 years, we have switched to 
                        CI/CD, IaaS, Kubernetes, PaaS on major providers like AWS, Azure, Google, Digital Ocean.
                    </p>
                    <a (click)=scrollTo(cloudEngineering) class="default-btn">See More <span></span></a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Featured Services Area -->

<!-- Start Partner Area -->
<!-- <div class="partner-area ptb-100 pt-0">
    <div class="container">
        <span class="sub-title">Happy customers:</span>
        <div class="partner-slides owl-carousel owl-theme">
            <div class="single-partner-item">
                <a href="#">
                    <img src="assets/img/partner-image/Emart.png" alt="image">
                    <img src="assets/img/partner-image/Emart.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#">
                    <img src="assets/img/partner-image/Ivanov.png" alt="image">
                    <img src="assets/img/partner-image/Ivanov.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#">
                    <img src="assets/img/partner-image/Krissy.png" alt="image">
                    <img src="assets/img/partner-image/Krissy.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#">
                    <img src="assets/img/partner-image/Organic.png" alt="image">
                    <img src="assets/img/partner-image/Organic.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#">
                    <img src="assets/img/partner-image/Trendix.png" alt="image">
                    <img src="assets/img/partner-image/Trendix.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#">
                    <img src="assets/img/partner-image/CH.png" alt="image">
                    <img src="assets/img/partner-image/CH.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#">
                    <img src="assets/img/partner-image/Cosmetic.png" alt="image">
                    <img src="assets/img/partner-image/Cosmetic.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#">
                    <img src="assets/img/partner-image/Mitov.png" alt="image">
                    <img src="assets/img/partner-image/Mitov.png" alt="image">
                </a>
            </div>
        </div>
    </div>
</div> -->
<!-- End Partner Area -->

<!-- Start Overview Area -->
<section class="overview-area ptb-100 pt-0" #servicess>
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Services</span>
            <h2>Software Solutions For Your Needs</h2>
            <p></p>
        </div>

        <div class="overview-box">
            <div class="overview-content" #productPrototyping>
                <div class="content">
                    <span class="sub-title">Product Prototyping</span>
                    <h2>UI and UX Prototyping</h2>
                    <p>Following the latest trends, we create user experience easy to use and navigate. We start off with 
                        a wireframe and finish with a high fidelity design. We keep constant communication with our 
                        customers, especially in this phase.
                    </p>

                    <div class="features-text">
                        <h4><i class="flaticon-tick"></i>Wireframes, Low Fidelity, High Fidelity</h4>
                        <!-- <p>We understand your requirements and create a rough look of what the app will look like.</p>
                        <p>Once you approve the wireframes, we create the design without taking into account the details.</p>
                        <p>When you're happy with the design, we make the final look with highest accuracy.</p> -->
                    </div>

                    <div class="features-text">
                        <h4><i class="flaticon-tick"></i>User Experience Prototyping</h4>
                    </div>

                    <a routerLink="/product-prototyping" class="default-btn">Learn More <span></span></a>
                </div>
            </div>

            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/ui_ux.svg" alt="UI & UX">
                </div>
            </div>
        </div>

        <div class="overview-box">
            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/app_api.svg" alt="APP & API">
                </div>
            </div>

            <div class="overview-content" #softwareDevelopment>
                <div class="content right-content">
                    <span class="sub-title">Software Development</span>
                    <h2>Apps and API</h2>
                    <p>
                        Having excellent Python developers, we are able to quickly build an API back-end based on REST 
                        frameworks or GraphQL. We integrate native machine learning models into back-end components and 
                        queues to empower real-time applications with AI. Our front-end engineers follow all W3C standards 
                        and use the best web app frameworks to build the UI.
                    </p>
                    <div class="features-text">
                        <h4><i class="flaticon-tick"></i>Django, Flask, Falcon, Web2py, Requests</h4>
                        <p></p>
                    </div>

                    <div class="features-text">
                        <h4><i class="flaticon-tick"></i>Angular, React, Vue; VanillaJS (ES6)</h4>
                        <p></p>
                    </div>

                    <a routerLink="/software-development" class="default-btn">Learn More <span></span></a>
                </div>
            </div>
        </div>

        <div class="overview-box">
            <div class="overview-content" #dataScience>
                <div class="content">
                    <span class="sub-title">Software Development</span>
                    <h2>Data and AI</h2>
                    <p>
                        We live in a sea of data and our task is to extract valuable knowledge from it to help our 
                        clients optimize their work and save time and money. Python is the industry standard tool for 
                        data science and our mathematical background motivates us even further. We use the best 
                        libraries to implement higher level of AI automated applications.
                    </p>

                    <div class="features-text">
                        <h4><i class="flaticon-tick"></i>Numpy, SciPy, Pandas, Matplotlib</h4>
                    </div>

                    <div class="features-text">
                        <h4><i class="flaticon-tick"></i>TensorFlow, Keras, PyTourch, Scikit-learn</h4>
                    </div>

                    <a routerLink="/data-science" class="default-btn">Learn More <span></span></a>
                </div>
            </div>

            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/data_ai.svg" alt="Data & AI">
                </div>
            </div>
        </div>

        <div class="overview-box">
            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/ci_cd.svg" alt="CI CD">
                </div>
            </div>

            <div class="overview-content" #cloudEngineering>
                <div class="content right-content">
                    <span class="sub-title">Cloud Engineering</span>
                    <h2>CI & CD</h2>
                    <p>
                        Any software project requires an infrastructure and our devops team knows how to handle it. 
                        Automation is the key to having reliable software products and we work with a variety of such 
                        tools to achieve the best results.
                    </p>

                    <div class="features-text">
                        <h4><i class="flaticon-tick"></i>Kubernetees, Docker, Heroku</h4>
                    </div>

                    <div class="features-text">
                        <h4><i class="flaticon-tick"></i>AWS, GCS, Azure, Digital Ocean</h4>
                    </div>

                    <a routerLink="/cloud-engineering" class="default-btn">Learn More <span></span></a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Overview Area -->

<!-- Start Features Area -->
<!-- <section class="features-area ptb-100 pt-0">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Features</span>
            <h2>We always try to understand our customers' expectation</h2>
        </div>

        <div class="tab features-list-tab">
            <ul class="tabs">
                <li><a href="#" class="bg-fa7070">
                        <i class="flaticon-achievement"></i>
                        <span>User Experience</span>
                    </a></li>

                <li><a href="#" class="bg-00aeff">
                        <i class="flaticon-architecture"></i>
                        <span>Product Design</span>
                    </a></li>

                <li><a href="#" class="bg-c679e3">
                        <i class="flaticon-data"></i>
                        <span>Development</span>
                    </a></li>

                <li><a href="#" class="bg-eb6b3d">
                        <i class="flaticon-analytics"></i>
                        <span>Infrastructure</span>
                    </a></li>

                <li><a href="#">
                        <i class="flaticon-digital-marketing"></i>
                        <span>Advertising</span>
                    </a></li>

                <li><a href="#" class="bg-f78acb">
                        <i class="flaticon-research"></i>
                        <span>Support</span>
                    </a></li>
            </ul>

            <div class="tab_content">
                <div class="tabs_item">
                    <div class="features-overview">
                        <div class="overview-content">
                            <div class="content">
                                TO COMMENT <span class="sub-title">Define Your Choices</span>
                                <h2>User Experience</h2>
                                <p>We are specialised in:</p>

                                <ul class="features-list">
                                    <li><span><i class="flaticon-tick"></i> </span></li>
                                    <li><span><i class="flaticon-tick"></i> </span></li>
                                    <li><span><i class="flaticon-tick"></i> </span></li>
                                    <li><span><i class="flaticon-tick"></i> </span></li>
                                    <li><span><i class="flaticon-tick"></i> </span></li>
                                    <li><span><i class="flaticon-tick"></i> </span></li>
                                </ul>
                            </div>
                        </div>

                        <div class="overview-image">
                            <div class="image">
                                <img src="assets/img/features-image/1.jpg" alt="image">
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tabs_item">
                    <div class="features-overview">

                        <div class="overview-content">
                            <div class="content">
                                <h2>Product Design</h2>
                                <p>We are specialised in:</p>

                                <ul class="features-list">
                                    <li><span><i class="flaticon-tick"></i> </span></li>
                                    <li><span><i class="flaticon-tick"></i> </span></li>
                                    <li><span><i class="flaticon-tick"></i> </span></li>
                                    <li><span><i class="flaticon-tick"></i> </span></li>
                                    <li><span><i class="flaticon-tick"></i> </span></li>
                                    <li><span><i class="flaticon-tick"></i> </span></li>
                                </ul>
                            </div>
                        </div>

                        <div class="overview-image">
                            <div class="image">
                                <img src="assets/img/features-image/1.jpg" alt="image">
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tabs_item">
                    <div class="features-overview">
                        <div class="overview-content">
                            <div class="content">
                                <h2>Development</h2>
                                <p>We are specialised in:</p>

                                <ul class="features-list">
                                    <li><span><i class="flaticon-tick"></i> Python</span></li>
                                    <li><span><i class="flaticon-tick"></i> Django</span></li>
                                    <li><span><i class="flaticon-tick"></i> VueJS</span></li>
                                    <li><span><i class="flaticon-tick"></i> Angular</span></li>
                                    <li><span><i class="flaticon-tick"></i> Machine Learning</span></li>
                                    <li><span><i class="flaticon-tick"></i> Data science</span></li>
                                </ul>
                            </div>
                        </div>

                        <div class="overview-image">
                            <div class="image">
                                <img src="assets/img/features-image/1.jpg" alt="image">
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tabs_item">
                    <div class="features-overview">

                        <div class="overview-content">
                            <div class="content">
                                <h2>Infrastructure</h2>
                                <p>We are specialised in:</p>

                                <ul class="features-list">
                                    <li><span><i class="flaticon-tick"></i> Google Cloud</span></li>
                                    <li><span><i class="flaticon-tick"></i> Amazon Web Services</span></li>
                                    <li><span><i class="flaticon-tick"></i> Kubernetees</span></li>
                                    <li><span><i class="flaticon-tick"></i> Docker</span></li>
                                    <li><span><i class="flaticon-tick"></i> CI/CD Pipeline</span></li>
                                    <li><span><i class="flaticon-tick"></i> Terraform</span></li>
                                </ul>
                            </div>
                        </div>

                        <div class="overview-image">
                            <div class="image">
                                <img src="assets/img/features-image/1.jpg" alt="image">
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tabs_item">
                    <div class="features-overview">
                        <div class="overview-content">
                            <div class="content">
                                <h2>Advertising</h2>
                                <p>We are specialised in:</p>

                                <ul class="features-list">
                                    <li><span><i class="flaticon-tick"></i> Web Design</span></li>
                                    <li><span><i class="flaticon-tick"></i> Logo Design</span></li>
                                    <li><span><i class="flaticon-tick"></i> Social Media Images</span></li>
                                    <li><span><i class="flaticon-tick"></i> Photo editing</span></li>
                                    <li><span><i class="flaticon-tick"></i> Business Cards</span></li>
                                    <li><span><i class="flaticon-tick"></i> Flyers</span></li>
                                </ul>
                            </div>
                        </div>

                        <div class="overview-image">
                            <div class="image">
                                <img src="assets/img/features-image/1.jpg" alt="image">
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tabs_item">
                    <div class="features-overview">

                        <div class="overview-content">
                            <div class="content">
                                <h2>Support</h2>
                                <p>We are specialised in:</p>

                                <ul class="features-list">
                                    <li><span><i class="flaticon-tick"></i> Maintaining apps</span></li>
                                    <li><span><i class="flaticon-tick"></i> Building new features</span></li>
                                    <li><span><i class="flaticon-tick"></i> </span></li>
                                    <li><span><i class="flaticon-tick"></i> </span></li>
                                    <li><span><i class="flaticon-tick"></i> </span></li>
                                    <li><span><i class="flaticon-tick"></i> </span></li>
                                </ul>
                            </div>
                        </div>

                        <div class="overview-image">
                            <div class="image">
                                <img src="assets/img/features-image/1.jpg" alt="image">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="shape-img7"><img src="assets/img/shape/7.png" alt="image"></div>
    <div class="shape-img2"><img src="assets/img/shape/2.svg" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/3.svg" alt="image"></div>
    <div class="shape-img4"><img src="assets/img/shape/4.png" alt="image"></div>
</section> -->
<!-- End Features Area -->

<!-- Start Fun Facts Area -->
<!-- <section class="fun-facts-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="single-fun-facts">
                    <h3><span class="odometer" data-count="324">00</span><span class="sign-icon">+</span></h3>
                    <p>Project Completed</p>
                    <div class="back-text">P</div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-fun-facts">
                    <h3><span class="odometer" data-count="100">00</span><span class="sign-icon">%</span></h3>
                    <p>Client Satisfaction</p>
                    <div class="back-text">C</div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-fun-facts">
                    <h3><span class="odometer" data-count="13">00</span></h3>
                    <p>Awards</p>
                    <div class="back-text">A</div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-fun-facts">
                    <h3><span class="odometer" data-count="48">00</span><span class="sign-icon">+</span></h3>
                    <p>Expert Members</p>
                    <div class="back-text">E</div>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!-- End Fun Facts Area -->

<!-- Start Projects Area -->
<!-- <section class="projects-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Project Gallery</span>
            <h2>We’ve done lots of work, let’s check some here</h2>
        </div>
    </div>

    <div class="container-fluid">
        <div class="projects-slides owl-carousel owl-theme">
            <div class="single-projects-box">
                <img src="assets/img/projects-image/ArtFlowers.png" alt="image">

                <div class="projects-content">
                    <h3><a href="#">Art Flowers</a></h3>
                    <span class="category">Online Store</span>
                </div>

                <div class="plus-icon">
                    <a href="assets/img/projects-image/ArtFlowers.png" class="popup-btn">
                        <span></span>
                    </a>
                </div>
            </div>

            <div class="single-projects-box">
                <img src="assets/img/projects-image/Bionia.jpg" alt="image">

                <div class="projects-content">
                    <h3><a href="#">Bionia</a></h3>
                    <span class="category">Online Store</span>
                </div>

                <div class="plus-icon">
                    <a href="assets/img/projects-image/Bionia.jpg" class="popup-btn">
                        <span></span>
                    </a>
                </div>
            </div>

            <div class="single-projects-box">
                <img src="assets/img/projects-image/EIP.png" alt="image">

                <div class="projects-content">
                    <h3><a href="#">European Investment Projects</a></h3>
                    <span class="category">Web Application</span>
                </div>

                <div class="plus-icon">
                    <a href="assets/img/projects-image/EIP.png" class="popup-btn">
                        <span></span>
                    </a>
                </div>
            </div>

            <div class="single-projects-box">
                <img src="assets/img/projects-image/Euroservice21.png" alt="image">

                <div class="projects-content">
                    <h3><a href="#">Euroservice 21</a></h3>
                    <span class="category">Company Website</span>
                </div>

                <div class="plus-icon">
                    <a href="assets/img/projects-image/Euroservice21.png" class="popup-btn">
                        <span></span>
                    </a>
                </div>
            </div>

            <div class="single-projects-box">
                <img src="assets/img/projects-image/GG.png" alt="image">

                <div class="projects-content">
                    <h3><a href="#">G & G</a></h3>
                    <span class="category">Company Website</span>
                </div>

                <div class="plus-icon">
                    <a href="assets/img/projects-image/GG.png" class="popup-btn">
                        <span></span>
                    </a>
                </div>
            </div>

            <div class="single-projects-box">
                <img src="assets/img/projects-image/IBC.jpg" alt="image">

                <div class="projects-content">
                    <h3><a href="#">International Business and Consultations</a></h3>
                    <span class="category">Web Apllication</span>
                </div>

                <div class="plus-icon">
                    <a href="assets/img/projects-image/IBC.jpg" class="popup-btn">
                        <span></span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!-- End Projects Area -->

<!-- Start Team Area -->
<!-- <section class="team-area ptb-100 pt-0">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Team</span>
            <h2>Meet Our Awesome Founders</h2>
        </div>

        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="single-team-box">
                    <div class="image">
                        <img src="assets/img/team-image/ceo.jpg" alt="CEO">
                    </div>

                    <div class="content">
                        <h3>Jenny Smith</h3>
                        <span>CEO, Co-Founder</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="single-team-box">
                    <div class="image">
                        <img src="assets/img/team-image/cto.jpg" alt="CTO">
                    </div>

                    <div class="content">
                        <h3>John Smith</h3>
                        <span>CTO, Co-Founder</span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="shape-img2"><img src="assets/img/shape/2.svg" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/3.svg" alt="image"></div>
    <div class="shape-img4"><img src="assets/img/shape/4.png" alt="image"></div>
    <div class="shape-img5"><img src="assets/img/shape/5.png" alt="image"></div>
    <div class="shape-img6"><img src="assets/img/shape/6.png" alt="image"></div>
    <div class="shape-img9"><img src="assets/img/shape/9.png" alt="image"></div>
    <div class="shape-img10"><img src="assets/img/shape/10.png" alt="image"></div>
</section> -->
<!-- End Team Area -->

<!-- Start Solution Area -->
<!-- <section class="solution-area ptb-100 extra-pb jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="solution-content">
                    <span class="sub-title">Watch Our Video</span>
                    <h2>Get Better Solution For Your Business</h2>
                    <p>No fake products and services. The customer is king, their lives and needs are the inspiration.
                    </p>

                    <a href="#" class="default-btn">Contact Us <span></span></a>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="solution-video">
                    <a href="https://www.youtube.com/watch?v=bk7McNUjWgw" class="video-btn popup-youtube"><i
                            class="flaticon-play-button"></i></a>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!-- End Solution Area -->

<!-- Start Feedback Area -->
<!-- <section class="feedback-area ptb-100 bg-color">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Testimonials</span>
            <h2>Feedback From Our Clients</h2>
        </div>
    </div>

    <div class="feedback-slides owl-carousel owl-theme">
        <div class="single-feedback-item">
            <p>“We believe brand interaction is key in communication. Real innovations and a positive customer
                experience are the heart of successful communication. No fake products and services.”</p>

            <div class="client-info">
                <img src="assets/img/client-image/1.jpg" alt="image">

                <h3>Jason Roy</h3>
                <span>Manager</span>
            </div>
        </div>

        <div class="single-feedback-item">
            <p>“We believe brand interaction is key in communication. Real innovations and a positive customer
                experience are the heart of successful communication. No fake products and services.”</p>

            <div class="client-info">
                <img src="assets/img/client-image/2.jpg" alt="image">

                <h3>James Anderson</h3>
                <span>Web Developer</span>
            </div>
        </div>

        <div class="single-feedback-item">
            <p>“We believe brand interaction is key in communication. Real innovations and a positive customer
                experience are the heart of successful communication. No fake products and services.”</p>

            <div class="client-info">
                <img src="assets/img/client-image/3.jpg" alt="image">

                <h3>Sarah Taylor</h3>
                <span>Designer</span>
            </div>
        </div>

        <div class="single-feedback-item">
            <p>“We believe brand interaction is key in communication. Real innovations and a positive customer
                experience are the heart of successful communication. No fake products and services.”</p>

            <div class="client-info">
                <img src="assets/img/client-image/4.jpg" alt="image">

                <h3>Steven Smith</h3>
                <span>Manager</span>
            </div>
        </div>

        <div class="single-feedback-item">
            <p>“We believe brand interaction is key in communication. Real innovations and a positive customer
                experience are the heart of successful communication. No fake products and services.”</p>

            <div class="client-info">
                <img src="assets/img/client-image/5.jpg" alt="image">

                <h3>Tom Nessham</h3>
                <span>EnvyTheme</span>
            </div>
        </div>
    </div>
</section> -->
<!-- End Feedback Area -->

<!-- Start Pricing Area -->
<!-- <section class="pricing-area ptb-100 pt-0">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Pricing Plan</span>
            <h2>Choose Your Best Plan</h2>
            <p>Real innovations and a positive customer experience are the heart of successful communication. No fake
                products and services.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-pricing-box">
                    <div class="pricing-header">
                        <h3>Basic</h3>
                    </div>

                    <div class="price">
                        <sub>$</sub>
                        39.99
                        <sub>/mo</sub>
                    </div>

                    <ul class="price-features-list">
                        <li><i class="flaticon-tick"></i> 1 Projects</li>
                        <li><i class="flaticon-tick"></i> Email Support</li>
                        <li><i class="flaticon-tick"></i> Phone Support</li>
                        <li><i class="flaticon-tick"></i> Article Promotion</li>
                        <li><i class="flaticon-tick"></i> Editorial Services</li>
                        <li><i class="flaticon-close"></i> Profile Management</li>
                        <li><i class="flaticon-close"></i> Selection Support</li>
                    </ul>

                    <a href="#" class="get-started-btn">Get Started</a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-pricing-box red">
                    <div class="pricing-header">
                        <h3>Starter</h3>
                    </div>

                    <div class="price">
                        <sub>$</sub>
                        49.99
                        <sub>/mo</sub>
                    </div>

                    <ul class="price-features-list">
                        <li><i class="flaticon-tick"></i> 2 Projects</li>
                        <li><i class="flaticon-tick"></i> Email Support</li>
                        <li><i class="flaticon-tick"></i> Phone Support</li>
                        <li><i class="flaticon-tick"></i> Article Promotion</li>
                        <li><i class="flaticon-tick"></i> Editorial Services</li>
                        <li><i class="flaticon-tick"></i> Profile Management</li>
                        <li><i class="flaticon-close"></i> Selection Support</li>
                    </ul>

                    <a href="#" class="get-started-btn">Get Started</a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                <div class="single-pricing-box orange">
                    <div class="pricing-header">
                        <h3>Extended</h3>
                    </div>

                    <div class="price">
                        <sub>$</sub>
                        59.99
                        <sub>/mo</sub>
                    </div>

                    <ul class="price-features-list">
                        <li><i class="flaticon-tick"></i> 3 Projects</li>
                        <li><i class="flaticon-tick"></i> Email Support</li>
                        <li><i class="flaticon-tick"></i> Phone Support</li>
                        <li><i class="flaticon-tick"></i> Article Promotion</li>
                        <li><i class="flaticon-tick"></i> Editorial Services</li>
                        <li><i class="flaticon-tick"></i> Profile Management</li>
                        <li><i class="flaticon-tick"></i> Selection Support</li>
                    </ul>

                    <a href="#" class="get-started-btn">Get Started</a>
                </div>
            </div>
        </div>
    </div>

    <div class="shape-img2"><img src="assets/img/shape/2.svg" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/3.svg" alt="image"></div>
    <div class="shape-img4"><img src="assets/img/shape/4.png" alt="image"></div>
    <div class="shape-img5"><img src="assets/img/shape/5.png" alt="image"></div>
    <div class="shape-img6"><img src="assets/img/shape/6.png" alt="image"></div>
    <div class="shape-img9"><img src="assets/img/shape/9.png" alt="image"></div>
    <div class="shape-img10"><img src="assets/img/shape/10.png" alt="image"></div>
</section> -->
<!-- End Pricing Area -->

<!-- Start Subscribe Area -->
<!-- <section class="subscribe-area bg-F4F7FC">
    <div class="container-fluid p-0">
        <div class="subscribe-inner-area jarallax" data-jarallax='{"speed": 0.3}'>
            <div class="subscribe-content">
                <span class="sub-title"></span>
                <h2>Subscribe to our newsletter to receive latest interesting blog posts and to get promotions!</h2>

                <form class="newsletter-form" data-toggle="validator">
                    <input type="email" class="input-newsletter" placeholder="Enter your email" name="EMAIL" required
                        autocomplete="off">

                    <button type="submit">Subscribe</button>
                    <div id="validator-newsletter" class="form-result"></div>
                </form>
            </div>
        </div>
    </div>

    <div class="shape-img2"><img src="assets/img/shape/2.svg" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/3.svg" alt="image"></div>
    <div class="shape-img4"><img src="assets/img/shape/4.png" alt="image"></div>
    <div class="shape-img5"><img src="assets/img/shape/5.png" alt="image"></div>
    <div class="shape-img6"><img src="assets/img/shape/6.png" alt="image"></div>
</section> -->
<!-- End Subscribe Area -->

<!-- Start Blog Area -->
<!-- <section class="blog-area ptb-100 bg-F4F7FC">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Latest News</span>
            <h2>Our Latest Articles And News For Our Clients</h2>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a href="#"><img src="assets/img/blog-image/terraform.jpg" alt="image"></a>
                    </div>

                    <div class="post-content">
                        <div class="post-meta">
                            <ul>
                                <li>By: <a href="#">Kiril Dimitrov</a></li>
                                <li>November 24, 2020</li>
                            </ul>
                        </div>

                        <h3><a href="#">How to Upload Folders to GCS using Terraform v0.12.x with Google Provider</a></h3>
                        <p>How to continuously transfer folders to a Google Cloud Storage (GCS) location and...</p>

                        <a href="#" class="read-more-btn">Read More <i class="flaticon-right-arrow"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a href="#"><img src="assets/img/blog-image/botnet.jpeg" alt="image"></a>
                    </div>

                    <div class="post-content">
                        <div class="post-meta">
                            <ul>
                                <li>By: <a href="#">Kiril Dimitrov</a></li>
                                <li>January 26, 2021</li>
                            </ul>
                        </div>

                        <h3><a href="#">BotNet traffic statistics with ELK Stack</a></h3>
                        <p>Have you ever wondered how many of your website visits come from Botnets?</p>

                        <a href="#" class="read-more-btn">Read More <i class="flaticon-right-arrow"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a href="#"><img src="assets/img/blog-image/3.jpg" alt="image"></a>
                    </div>

                    <div class="post-content">
                        <div class="post-meta">
                            <ul>
                                <li>By: <a href="#">Steven Smith</a></li>
                                <li>June 25, 2019</li>
                            </ul>
                        </div>

                        <h3><a href="#">How To Use Music To Boost Your Business</a></h3>
                        <p>Lorem ipsum dolor sit amet, constetur adipiscing elit, sed do eiusmod tempor incididunt.</p>

                        <a href="#" class="read-more-btn">Read More <i class="flaticon-right-arrow"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="shape-img2"><img src="assets/img/shape/2.svg" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/3.svg" alt="image"></div>
</section> -->
<!-- End Blog Area -->