<!-- Start Main Banner Area -->
<div class="hero-banner services">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-12">
                        <div class="hero-banner-content">
                            <h1>Project Demand Forecast</h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Main Banner Area -->

<!-- Start Details Section Area -->
<div class="details-section-area">
    <div class="container-fluid">
        <div class="row dark-bg">
            <div class="row-padding">
                <div class="col-12 col-md-7 img-holder">
                    <div class="section-area-img img1">
                    </div>
                </div>
                <div class="col-12 col-md-5 text-holder">
                    <div class="section-area-text">
                        <div class="details-title">The Problem</div>
                        <div class="details-text">
                            <div class="mb-25">
                                Imagine you are a business owner and know what the next orders from your customers will be, 
                                what products they'll order. Sounds good, doesn't it? In the recent years many companies 
                                have started putting resources into that direction. What they want is a software that will 
                                predict the customers' next orders. We developed such web application for our client. 
                                Being able to have an accurate demand forecast helped him in not 1 or 2 cases. His biggest 
                                issue was he had short space in his company's storage. So he didn't know what resources to 
                                keep there. He has to be able to manufacture products as soon as the order is received, 
                                but for every 2-nd order, he did not have the needed supplies and had to wait days before 
                                their delivery. Only then was he able to start building the product.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row gray-bg">
            <div class="row-padding">
                <div class="col-12 col-md-5 text-holder">
                    <div class="section-area-text">
                        <div class="details-title">The Solution</div>
                        <div class="details-text">
                            <div class="mb-25">
                                What we did exceeded even our expectations! We created a Supply Chain Management software 
                                system that is also able to predict the customers behavior with the help of Machine Learning 
                                algorithms. Our client did not have a SCM system at all, so he wanted us to build him one, 
                                customly developed for his purposes. Using Django and Angular, we have built a web application 
                                that tracks products and supplies amount and characteristics. It also presents useful statistical 
                                summary that helps him oversee the big picture. His business was mainly in the Internet, selling 
                                more than 500 products every day. We analyzed the customers behavior based on different 
                                factors such as date, time, season, location, time spent viewing products and others. Only 2 
                                months of data gathering were needed for our software system to start making accurate predictions.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-7 img-holder">
                    <div class="section-area-img img2">
                    </div>
                </div>
            </div>
        </div>
        <div class="row dark-bg">
            <div class="row-padding">
                <div class="col-12 col-md-7 img-holder">
                    <div class="section-area-img img3">
                    </div>
                </div>
                <div class="col-12 col-md-5 text-holder">
                    <div class="section-area-text">
                        <div class="details-title">Technologies</div>
                        <div class="details-text">
                            <div class="mb-25">
                                To do this amazing project, we used some of the latest technologies that are currently on 
                                the market. We created a Single Page Applciation (SPA) using Angular. For the backend part 
                                of the project, we chose what is considered the best programming language for Machine Learning - 
                                Python. The framework that we used was Django. To make the demand predictions, we have combined 
                                a couple of methods - Trend Projection applied together with Market Research, the Delphi Method 
                                and other econometric algorithms. We are planning on expanding the software and sell it to other 
                                companies too. Another important feature that we are currently working on is reading information 
                                from barcode scanner devices (either 1D or 2D).
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Details Section Area -->

<div class="partner-area ptb-100">
    <div class="container">
        <div class="partner-slides owl-carousel owl-theme">
            <div class="single-partner-item">
                <a href="#">
                    <img src="assets/img/partner-image/vuejs.png" alt="image">
                    <img src="assets/img/partner-image/vuejs.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#">
                    <img src="assets/img/partner-image/react.png" alt="image">
                    <img src="assets/img/partner-image/react.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#">
                    <img src="assets/img/partner-image/angular.png" alt="image">
                    <img src="assets/img/partner-image/angular.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#">
                    <img src="assets/img/partner-image/python.png" alt="image">
                    <img src="assets/img/partner-image/python.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#">
                    <img src="assets/img/partner-image/mysql.png" alt="image">
                    <img src="assets/img/partner-image/mysql.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#">
                    <img src="assets/img/partner-image/nodejs.png" alt="image">
                    <img src="assets/img/partner-image/nodejs.png" alt="image">
                </a>
            </div>
        </div>
    </div>
</div>