<!-- Start Main Banner Area -->
<div class="hero-banner services">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-12">
                        <div class="hero-banner-content">
                            <h1>Software Development</h1>
                            <a href="javascript:void(Tawk_API.popup())" class="default-btn">Get Estimate<span></span></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Main Banner Area -->

<!-- Start Details Section Area -->
<div class="details-section-area">
    <div class="container-fluid">
        <div class="row dark-bg">
            <div class="row-padding">
                <div class="col-12 col-md-7 img-holder">
                    <div class="section-area-img img1">
                    </div>
                </div>
                <div class="col-12 col-md-5 text-holder">
                    <div class="section-area-text">
                        <div class="details-title">Front-End</div>
                        <div class="details-text">
                            <div class="mb-25">
                            We use the latest frameworks - VueJS, Angular 11, ReactJS with SASS / LESS. Depending on the 
                            app architecture though, we also write VanillaJS (ES6). We love CSS Grids, Bootstrap and follow 
                            all W3C standards.
                            </div>
                            <div class="mb-25">
                                <i class="flaticon-tick"></i>
                                Fully decoupled API-first SPA apps
                            </div>
                            <div class="mb-25">
                                <i class="flaticon-tick"></i>
                                Progressive Web Apps
                            </div>
                            <div class="mb-25">
                                <i class="flaticon-tick"></i>
                                Optimized state management
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row gray-bg">
            <div class="row-padding">
                <div class="col-12 col-md-5 text-holder">
                    <div class="section-area-text">
                        <div class="details-title">Back-End</div>
                        <div class="details-text">
                            <div class="mb-25">
                            We’re crazy about Python and we are constantly bringing all new trends into our apps. We 
                            develop web applications based on robust frameworks like Django & Flask and store data 
                            using MySQL or PostgreSQL.
                            </div>
                            <div class="mb-25">
                                <i class="flaticon-tick"></i>
                                Security is our top priorirty
                            </div>
                            <div class="mb-25">
                                <i class="flaticon-tick"></i>
                                Serverless apps
                            </div>
                            <div class="mb-25">
                                <i class="flaticon-tick"></i>
                                Separate back-end for simplicity
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-7 img-holder">
                    <div class="section-area-img img2">
                    </div>
                </div>
            </div>
        </div>
        <div class="row dark-bg">
            <div class="row-padding">
                <div class="col-12 col-md-7 img-holder">
                    <div class="section-area-img img3">
                    </div>
                </div>
                <div class="col-12 col-md-5 text-holder">
                    <div class="section-area-text">
                        <div class="details-title">Agile Processes</div>
                        <div class="details-text">
                            <div class="mb-25">
                            We follow a few principles and we believe they truly matter! Our goal is to always keep our 
                            customers happy.  We know what business owners value and we constantly work in that direction.
                            </div>
                            <div class="mb-25">
                                <i class="flaticon-tick"></i>
                                Open to changes, regardless of the stage of the project!
                            </div>
                            <div class="mb-25">
                                <i class="flaticon-tick"></i>
                                Frequent delivery to keep you updated at all times!
                            </div>
                            <div class="mb-25">
                                <i class="flaticon-tick"></i>
                                Constant communication to always have goals aligned!
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- <div class="row gray-bg">
            <div class="row-padding">
                <div class="col-12 col-md-6">
                    <div class="section-area-text">
                        <div class="details-title">TITLE</div>
                        <div class="details-text">
                            We build fully decoupled API-first apps.
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <div class="section-area-img" id="img4">
                    </div>
                </div>
            </div>
        </div> -->
    </div>
</div>

<div class="partner-area ptb-100">
    <div class="container">
        <div class="partner-slides owl-carousel owl-theme">
            <div class="single-partner-item">
                <a href="#">
                    <img src="assets/img/partner-image/vuejs.png" alt="image" title="VueJS">
                    <img src="assets/img/partner-image/vuejs.png" alt="image" title="VueJS">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#">
                    <img src="assets/img/partner-image/react.png" alt="image" title="React">
                    <img src="assets/img/partner-image/react.png" alt="image" title="React">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#">
                    <img src="assets/img/partner-image/angular.png" alt="image" title="Angular">
                    <img src="assets/img/partner-image/angular.png" alt="image" title="Angular">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#">
                    <img src="assets/img/partner-image/python.png" alt="image" title="Python">
                    <img src="assets/img/partner-image/python.png" alt="image" title="Python">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#">
                    <img src="assets/img/partner-image/Django.png" alt="image" title="Django">
                    <img src="assets/img/partner-image/Django.png" alt="image" title="Django">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#">
                    <img src="assets/img/partner-image/nodejs.png" alt="image" title="NodeJS">
                    <img src="assets/img/partner-image/nodejs.png" alt="image" title="NodeJS">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#">
                    <img src="assets/img/partner-image/mysql.png" alt="image" title="MySQL">
                    <img src="assets/img/partner-image/mysql.png" alt="image" title="MySQL">
                </a>
            </div>
        </div>
    </div>
</div>
<!-- End Details Section Area -->