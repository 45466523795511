import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-project-ml-manufacturing',
  templateUrl: './project-ml-manufacturing.component.html',
  styleUrls: ['./project-ml-manufacturing.component.scss']
})
export class ProjectMLManufacturingComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
