<!-- Start Main Banner Area -->
<div class="hero-banner services">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-12">
                        <div class="hero-banner-content">
                            <h1>Project Machine Learning</h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Main Banner Area -->

<!-- Start Details Section Area -->
<div class="details-section-area">
    <div class="container-fluid">
        <div class="row dark-bg">
            <div class="row-padding">
                <div class="col-12 col-md-7 img-holder">
                    <div class="section-area-img img1">
                    </div>
                </div>
                <div class="col-12 col-md-5 text-holder">
                    <div class="section-area-text">
                        <div class="details-title">Predict Machine Failure</div>
                        <div class="details-text">
                            <div class="mb-25">
                                Our client was a furniture manufacturing company with 6 factories and more than 20,000 
                                employees. Every machine breakdown costed the company approximately $7,000 and that's why 
                                they decided they need to know when a machine will most likely crash. Implementing that 
                                feature, they were able to repair machines at lower costs in a convinient for them time 
                                (when the orders are not much). Along with that, we made an estimate of when certain parts 
                                will break down, so they'll have time to order spare parts and repair it on time. We took the records from these machines and used them to 
                                predict their life.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row gray-bg">
            <div class="row-padding">
                <div class="col-12 col-md-5 text-holder">
                    <div class="section-area-text">
                        <div class="details-title">How we did it</div>
                        <div class="details-text">
                            <div class="mb-25">
                                To make good and close to reality predictions for the lifetime of machines, we applied the 
                                Kaplan-Meier Model - a mathematical estimation to get the survival function. The result is a 
                                declining function placed on a coordinate system, showing when predefined events will take place.  
                                You can see that on the image above. 
                                On the image on the right, you can see part of our code for calculating parts break down. For that, 
                                we have applied some not that complex algorithms (like DTR and SVR) 
                                to get an approximate time when the most important parts will stop functioning. The test result was 0.96 
                                which is a perfect estimation score, keeping in mind that the max results is 1. 
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-7 img-holder">
                    <div class="section-area-img img2">
                    </div>
                </div>
            </div>
        </div>
        <div class="row dark-bg">
            <div class="row-padding">
                <div class="col-12 col-md-7 img-holder">
                    <div class="section-area-img img3">
                    </div>
                </div>
                <div class="col-12 col-md-5 text-holder">
                    <div class="section-area-text">
                        <div class="details-title">Testimonial</div>
                        <div class="details-text">
                            <div class="mb-25">
                                "We are absolutely amazed with the delivered results from AboveDev! We change machine parts when the 
                                software system they gave us, tells us to. And what our technicians see when changing those elements 
                                is that they were on the verge of breaking down and wouldn't have lasten long. I really did not believe 
                                it will work out, but I am truly impressed. We managed to pay off what we have paid for this software, only 
                                for 3 months! I reccommend every business to always adopt newest technologies, especially in the field of AI."
                                <br>
                                George Manoff
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Details Section Area -->

<div class="partner-area ptb-100">
    <div class="container">
        <div class="partner-slides owl-carousel owl-theme">
            <div class="single-partner-item">
                <a href="#">
                    <img src="assets/img/partner-image/vuejs.png" alt="image">
                    <img src="assets/img/partner-image/vuejs.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#">
                    <img src="assets/img/partner-image/react.png" alt="image">
                    <img src="assets/img/partner-image/react.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#">
                    <img src="assets/img/partner-image/angular.png" alt="image">
                    <img src="assets/img/partner-image/angular.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#">
                    <img src="assets/img/partner-image/python.png" alt="image">
                    <img src="assets/img/partner-image/python.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#">
                    <img src="assets/img/partner-image/mysql.png" alt="image">
                    <img src="assets/img/partner-image/mysql.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#">
                    <img src="assets/img/partner-image/nodejs.png" alt="image">
                    <img src="assets/img/partner-image/nodejs.png" alt="image">
                </a>
            </div>
        </div>
    </div>
</div>