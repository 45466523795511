import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, NgForm } from '@angular/forms';
import { ContactService } from 'src/app/contact.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  success: Boolean;
  error: Boolean;
  FormData: FormGroup;

  constructor(private builder: FormBuilder, private contact: ContactService) { }

  ngOnInit() {
    this.success = false;
    this.error = false;
    this.FormData = this.builder.group({
      Name: new FormControl('', [Validators.required]),
      Email: new FormControl('', [Validators.compose([Validators.required, Validators.email])]),
      Phone: new FormControl('', [Validators.required]),
      Subject: new FormControl('', [Validators.required]),
      Msg: new FormControl('', [Validators.required])
    })
  }

  onSubmit(FormData) {
    console.log(FormData)
    this.contact.PostMessage(FormData)
      .subscribe(response => {
        //location.href = 'https://mailthis.to/confirm'
        //console.log(response)
        this.success = true;
      }, error => {
        this.error = true;
        console.warn(error.responseText)
        console.log({ error })
      })
  }
}
