<!-- Start Page Title Area -->
<div class="page-title-area page-title-bg2">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Pricing</h2>

                    <ul>
                        <li><a href="#">Home</a></li>
                        <li>Pricing</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="shape-img2"><img src="assets/img/shape/2.svg" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/3.svg" alt="image"></div>
    <div class="shape-img4"><img src="assets/img/shape/4.png" alt="image"></div>
    <div class="shape-img5"><img src="assets/img/shape/5.png" alt="image"></div>
    <div class="shape-img7"><img src="assets/img/shape/7.png" alt="image"></div>
    <div class="shape-img8"><img src="assets/img/shape/8.png" alt="image"></div>
    <div class="shape-img9"><img src="assets/img/shape/9.png" alt="image"></div>
    <div class="shape-img10"><img src="assets/img/shape/10.png" alt="image"></div>
</div>
<!-- End Page Title Area -->

<!-- Start Pricing Area -->
<section class="pricing-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-pricing-box">
                    <div class="pricing-header">
                        <h3>Basic</h3>
                    </div>

                    <div class="price">
                        <sub>$</sub>
                        39.99
                        <sub>/mo</sub>
                    </div>

                    <ul class="price-features-list">
                        <li><i class="flaticon-tick"></i> 1 Projects</li>
                        <li><i class="flaticon-tick"></i> Email Support</li>
                        <li><i class="flaticon-tick"></i> Phone Support</li>
                        <li><i class="flaticon-tick"></i> Article Promotion</li>
                        <li><i class="flaticon-tick"></i> Editorial Services</li>
                        <li><i class="flaticon-close"></i> Profile Management</li>
                        <li><i class="flaticon-close"></i> Selection Support</li>
                    </ul>

                    <a href="#" class="get-started-btn">Get Started</a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-pricing-box red">
                    <div class="pricing-header">
                        <h3>Starter</h3>
                    </div>

                    <div class="price">
                        <sub>$</sub>
                        49.99
                        <sub>/mo</sub>
                    </div>

                    <ul class="price-features-list">
                        <li><i class="flaticon-tick"></i> 2 Projects</li>
                        <li><i class="flaticon-tick"></i> Email Support</li>
                        <li><i class="flaticon-tick"></i> Phone Support</li>
                        <li><i class="flaticon-tick"></i> Article Promotion</li>
                        <li><i class="flaticon-tick"></i> Editorial Services</li>
                        <li><i class="flaticon-tick"></i> Profile Management</li>
                        <li><i class="flaticon-close"></i> Selection Support</li>
                    </ul>

                    <a href="#" class="get-started-btn">Get Started</a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                <div class="single-pricing-box orange">
                    <div class="pricing-header">
                        <h3>Extended</h3>
                    </div>

                    <div class="price">
                        <sub>$</sub>
                        59.99
                        <sub>/mo</sub>
                    </div>

                    <ul class="price-features-list">
                        <li><i class="flaticon-tick"></i> 3 Projects</li>
                        <li><i class="flaticon-tick"></i> Email Support</li>
                        <li><i class="flaticon-tick"></i> Phone Support</li>
                        <li><i class="flaticon-tick"></i> Article Promotion</li>
                        <li><i class="flaticon-tick"></i> Editorial Services</li>
                        <li><i class="flaticon-tick"></i> Profile Management</li>
                        <li><i class="flaticon-tick"></i> Selection Support</li>
                    </ul>

                    <a href="#" class="get-started-btn">Get Started</a>
                </div>
            </div>
        </div>
    </div>

    <div class="shape-img2"><img src="assets/img/shape/2.svg" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/3.svg" alt="image"></div>
    <div class="shape-img4"><img src="assets/img/shape/4.png" alt="image"></div>
    <div class="shape-img5"><img src="assets/img/shape/5.png" alt="image"></div>
    <div class="shape-img6"><img src="assets/img/shape/6.png" alt="image"></div>
    <div class="shape-img9"><img src="assets/img/shape/9.png" alt="image"></div>
    <div class="shape-img10"><img src="assets/img/shape/10.png" alt="image"></div>
</section>
<!-- End Pricing Area -->
