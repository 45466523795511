import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-data-science',
  templateUrl: './data-science.component.html',
  styleUrls: ['./data-science.component.scss']
})
export class DataScienceComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
