<!-- Start Main Banner Area -->
<div class="hero-banner banner-bg1">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container-fluid">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12">
                        <div class="hero-banner-content">
                            <span class="sub-title">Welcome to Karlson</span>
                            <h1>Creative & Strategic Digital Marketing Agency</h1>
                            <p>We work hand-in-hand with industry-leading brands to help redefine the possibilities and
                                potential of digital engagements.</p>

                            <div class="btn-box">
                                <a href="#" class="default-btn">Get Started <span></span></a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="hero-banner-image">
                            <img src="assets/img/banner-image/main.png" alt="image">
                            <img src="assets/img/banner-image/1.png" class="wow fadeInDown" data-wow-delay=".9s"
                                alt="image">
                            <img src="assets/img/banner-image/2.png" class="wow fadeInDown" data-wow-delay=".6s"
                                alt="image">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="shape-img1"><img src="assets/img/shape/1.png" class="wow fadeInUp" data-wow-delay=".9s" alt="image">
    </div>
    <div class="shape-img2"><img src="assets/img/shape/2.svg" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/3.svg" alt="image"></div>
    <div class="shape-img4"><img src="assets/img/shape/4.png" alt="image"></div>
    <div class="shape-img5"><img src="assets/img/shape/5.png" alt="image"></div>
    <div class="shape-img6"><img src="assets/img/shape/6.png" alt="image"></div>
    <div class="shape-img7"><img src="assets/img/shape/7.png" alt="image"></div>
    <div class="shape-img8"><img src="assets/img/shape/8.png" alt="image"></div>
    <div class="shape-img9"><img src="assets/img/shape/9.png" alt="image"></div>
    <div class="shape-img10"><img src="assets/img/shape/10.png" alt="image"></div>
</div>
<!-- End Main Banner Area -->

<!-- Start Featured Services Area -->
<section class="featured-services-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Our Services</span>
            <h2>Our Featured Services</h2>
        </div>

        <div class="row">
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-featured-box">
                    <div class="icon">
                        <i class="flaticon-analytics"></i>
                    </div>

                    <h3>Marketing Analysis</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                        labore et dolore</p>

                    <a href="#" class="default-btn">Read More <span></span></a>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-featured-box">
                    <div class="icon color-facd60">
                        <i class="flaticon-mail"></i>
                    </div>

                    <h3>Email Marketing</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                        labore et dolore</p>

                    <a href="#" class="default-btn">Read More <span></span></a>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-6 offset-lg-0 offset-md-3 offset-sm-3">
                <div class="single-featured-box">
                    <div class="icon color-1ac0c6">
                        <i class="flaticon-research"></i>
                    </div>

                    <h3>Website Optimization</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                        labore et dolore</p>

                    <a href="#" class="default-btn">Read More <span></span></a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Featured Services Area -->

<!-- Start About Area -->
<section class="about-area ptb-100 pt-0">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-image">
                    <img src="assets/img/about-img1.png" alt="image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <span class="sub-title">About Us</span>
                    <h2>We Are Digital Explorers</h2>
                    <p>We believe brand interaction is key in communication. Real innovations and a positive customer
                        experience are the heart of successful communication. No fake products and services. The
                        customer is king, their lives and needs are the inspiration.</p>

                    <ul class="features-list">
                        <li><span><i class="fas fa-check"></i> Recommender systems</span></li>
                        <li><span><i class="fas fa-check"></i> Demand prediction</span></li>
                        <li><span><i class="fas fa-check"></i> Omnichannel analytics</span></li>
                        <li><span><i class="fas fa-check"></i> Lead generation</span></li>
                        <li><span><i class="fas fa-check"></i> Dedicated Developers</span></li>
                        <li><span><i class="fas fa-check"></i> 24/7 Support</span></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="shape-img3"><img src="assets/img/shape/3.svg" alt="image"></div>
    <div class="shape-img2"><img src="assets/img/shape/2.svg" alt="image"></div>
</section>
<!-- End About Area -->

<!-- Start Services Area -->
<section class="services-area ptb-100 bg-F4F7FC">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">What We Do</span>
            <h2>Provide Awesome Service With Our Tools</h2>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-digital-marketing"></i>
                    </div>

                    <h3><a href="#">Digital Marketing</a></h3>

                    <p>Lorem ipsum dolor sit amet elit sed, consectetur adipiscing elit, sed do eiusmod tempor
                        incididunt.</p>
                    <a href="#" class="read-more-btn">Read More <i class="flaticon-right-arrow"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon bg-00aeff">
                        <i class="flaticon-research"></i>
                    </div>

                    <h3><a href="#">Design & Development</a></h3>

                    <p>Lorem ipsum dolor sit amet elit sed, consectetur adipiscing elit, sed do eiusmod tempor
                        incididunt.</p>
                    <a href="#" class="read-more-btn">Read More <i class="flaticon-right-arrow"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon bg-f78acb">
                        <i class="flaticon-analytics"></i>
                    </div>

                    <h3><a href="#">Strategic Planing</a></h3>

                    <p>Lorem ipsum dolor sit amet elit sed, consectetur adipiscing elit, sed do eiusmod tempor
                        incididunt.</p>
                    <a href="#" class="read-more-btn">Read More <i class="flaticon-right-arrow"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon bg-cdf1d8">
                        <i class="flaticon-analysis"></i>
                    </div>

                    <h3><a href="#">SEO Consultancy</a></h3>

                    <p>Lorem ipsum dolor sit amet elit sed, consectetur adipiscing elit, sed do eiusmod tempor
                        incididunt.</p>
                    <a href="#" class="read-more-btn">Read More <i class="flaticon-right-arrow"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon bg-c679e3">
                        <i class="flaticon-mail"></i>
                    </div>

                    <h3><a href="#">Competitor Analysis</a></h3>

                    <p>Lorem ipsum dolor sit amet elit sed, consectetur adipiscing elit, sed do eiusmod tempor
                        incididunt.</p>
                    <a href="#" class="read-more-btn">Read More <i class="flaticon-right-arrow"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon bg-eb6b3d">
                        <i class="flaticon-seo"></i>
                    </div>

                    <h3><a href="#">Social Media Marketing</a></h3>

                    <p>Lorem ipsum dolor sit amet elit sed, consectetur adipiscing elit, sed do eiusmod tempor
                        incididunt.</p>
                    <a href="#" class="read-more-btn">Read More <i class="flaticon-right-arrow"></i></a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Services Area -->

<!-- Start Why Choose Us Area -->
<section class="why-choose-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="why-choose-content">
                    <span class="sub-title">Why Choose Us</span>
                    <h2>The Key To Your Motivation And Success</h2>
                    <p>We believe brand interaction is key in communication. Real innovations and a positive customer
                        experience are the heart of successful communication.</p>

                    <div class="features-text">
                        <h4><i class="flaticon-tick"></i> Core Development</h4>
                        <p>No fake products and services. The customer is king, their lives and needs are the
                            inspiration.</p>
                    </div>

                    <div class="features-text">
                        <h4><i class="flaticon-tick"></i> Define Your Choices</h4>
                        <p>No fake products and services. The customer is king, their lives and needs are the
                            inspiration.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="why-choose-image">
                    <img src="assets/img/why-choose-img1.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Why Choose Us Area -->

<!-- Start Fun Facts Area -->
<section class="fun-facts-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-fun-facts">
                    <h3><span class="odometer" data-count="324">00</span><span class="sign-icon">+</span></h3>
                    <p>Project Completed</p>
                    <div class="back-text">P</div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-fun-facts">
                    <h3><span class="odometer" data-count="100">00</span><span class="sign-icon">%</span></h3>
                    <p>Client Satisfaction</p>
                    <div class="back-text">C</div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-fun-facts">
                    <h3><span class="odometer" data-count="13">00</span></h3>
                    <p>Awards</p>
                    <div class="back-text">A</div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-fun-facts">
                    <h3><span class="odometer" data-count="48">00</span><span class="sign-icon">+</span></h3>
                    <p>Expert Members</p>
                    <div class="back-text">E</div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Fun Facts Area -->

<!-- Start Projects Area -->
<section class="projects-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Project Gallery</span>
            <h2>We’ve Done Lot’s Of Work, Let’s Check Some From Here</h2>
        </div>
    </div>

    <div class="container-fluid">
        <div class="projects-slides owl-carousel owl-theme">
            <div class="single-projects-box">
                <img src="assets/img/projects-image/1.jpg" alt="image">

                <div class="projects-content">
                    <h3><a href="#">Digital Marketing</a></h3>
                    <span class="category">Design</span>
                </div>

                <div class="plus-icon">
                    <a href="assets/img/projects-image/1.jpg" class="popup-btn">
                        <span></span>
                    </a>
                </div>
            </div>

            <div class="single-projects-box">
                <img src="assets/img/projects-image/2.jpg" alt="image">

                <div class="projects-content">
                    <h3><a href="#">Design & Development</a></h3>
                    <span class="category">Planing</span>
                </div>

                <div class="plus-icon">
                    <a href="assets/img/projects-image/2.jpg" class="popup-btn">
                        <span></span>
                    </a>
                </div>
            </div>

            <div class="single-projects-box">
                <img src="assets/img/projects-image/3.jpg" alt="image">

                <div class="projects-content">
                    <h3><a href="#">Strategic Planing</a></h3>
                    <span class="category">Marketing</span>
                </div>

                <div class="plus-icon">
                    <a href="assets/img/projects-image/3.jpg" class="popup-btn">
                        <span></span>
                    </a>
                </div>
            </div>

            <div class="single-projects-box">
                <img src="assets/img/projects-image/4.jpg" alt="image">

                <div class="projects-content">
                    <h3><a href="#">SEO Consultancy</a></h3>
                    <span class="category">Development</span>
                </div>

                <div class="plus-icon">
                    <a href="assets/img/projects-image/4.jpg" class="popup-btn">
                        <span></span>
                    </a>
                </div>
            </div>

            <div class="single-projects-box">
                <img src="assets/img/projects-image/5.jpg" alt="image">

                <div class="projects-content">
                    <h3><a href="#">Competitor Analysis</a></h3>
                    <span class="category">Design</span>
                </div>

                <div class="plus-icon">
                    <a href="assets/img/projects-image/5.jpg" class="popup-btn">
                        <span></span>
                    </a>
                </div>
            </div>

            <div class="single-projects-box">
                <img src="assets/img/projects-image/6.jpg" alt="image">

                <div class="projects-content">
                    <h3><a href="#">Social Media Marketing</a></h3>
                    <span class="category">Development</span>
                </div>

                <div class="plus-icon">
                    <a href="assets/img/projects-image/6.jpg" class="popup-btn">
                        <span></span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Projects Area -->

<!-- Start Features Area -->
<section class="features-area ptb-100 pt-0">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Our Features</span>
            <h2>We always try to understand customers expectation</h2>
        </div>

        <div class="tab features-list-tab">
            <ul class="tabs">
                <li><a href="#" class="bg-fa7070">
                        <i class="flaticon-achievement"></i>
                        <span>User Experience</span>
                    </a></li>

                <li><a href="#" class="bg-00aeff">
                        <i class="flaticon-architecture"></i>
                        <span>Product Design</span>
                    </a></li>

                <li><a href="#" class="bg-c679e3">
                        <i class="flaticon-digital-marketing"></i>
                        <span>Digital Marketing</span>
                    </a></li>

                <li><a href="#" class="bg-eb6b3d">
                        <i class="flaticon-analytics"></i>
                        <span>Branding</span>
                    </a></li>

                <li><a href="#">
                        <i class="flaticon-data"></i>
                        <span>Development</span>
                    </a></li>

                <li><a href="#" class="bg-f78acb">
                        <i class="flaticon-research"></i>
                        <span>Marketing</span>
                    </a></li>
            </ul>

            <div class="tab_content">
                <div class="tabs_item">
                    <div class="features-overview">
                        <div class="overview-content">
                            <div class="content">
                                <span class="sub-title">Define Your Choices</span>
                                <h2>User Experience</h2>
                                <p>We believe brand interaction is key in communication. Real innovations and a positive
                                    customer experience are the heart of successful communication. No fake products and
                                    services. The customer is king, their lives and needs are the inspiration.</p>

                                <ul class="features-list">
                                    <li><span><i class="flaticon-tick"></i> Open Source</span></li>
                                    <li><span><i class="flaticon-tick"></i> Optimal Choice</span></li>
                                    <li><span><i class="flaticon-tick"></i> High Security</span></li>
                                    <li><span><i class="flaticon-tick"></i> Great Advices</span></li>
                                    <li><span><i class="flaticon-tick"></i> Creative Layout</span></li>
                                    <li><span><i class="flaticon-tick"></i> Super Responsive</span></li>
                                </ul>
                            </div>
                        </div>

                        <div class="overview-image">
                            <div class="image">
                                <img src="assets/img/features-image/1.png" alt="image">
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tabs_item">
                    <div class="features-overview">
                        <div class="overview-image">
                            <div class="image">
                                <img src="assets/img/features-image/2.png" alt="image">
                            </div>
                        </div>

                        <div class="overview-content">
                            <div class="content">
                                <span class="sub-title">Define Your Choices</span>
                                <h2>Product Design</h2>
                                <p>We believe brand interaction is key in communication. Real innovations and a positive
                                    customer experience are the heart of successful communication. No fake products and
                                    services. The customer is king, their lives and needs are the inspiration.</p>

                                <ul class="features-list">
                                    <li><span><i class="flaticon-tick"></i> Open Source</span></li>
                                    <li><span><i class="flaticon-tick"></i> Optimal Choice</span></li>
                                    <li><span><i class="flaticon-tick"></i> High Security</span></li>
                                    <li><span><i class="flaticon-tick"></i> Great Advices</span></li>
                                    <li><span><i class="flaticon-tick"></i> Creative Layout</span></li>
                                    <li><span><i class="flaticon-tick"></i> Super Responsive</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tabs_item">
                    <div class="features-overview">
                        <div class="overview-content">
                            <div class="content">
                                <span class="sub-title">Define Your Choices</span>
                                <h2>Digital Marketing</h2>
                                <p>We believe brand interaction is key in communication. Real innovations and a positive
                                    customer experience are the heart of successful communication. No fake products and
                                    services. The customer is king, their lives and needs are the inspiration.</p>

                                <ul class="features-list">
                                    <li><span><i class="flaticon-tick"></i> Open Source</span></li>
                                    <li><span><i class="flaticon-tick"></i> Optimal Choice</span></li>
                                    <li><span><i class="flaticon-tick"></i> High Security</span></li>
                                    <li><span><i class="flaticon-tick"></i> Great Advices</span></li>
                                    <li><span><i class="flaticon-tick"></i> Creative Layout</span></li>
                                    <li><span><i class="flaticon-tick"></i> Super Responsive</span></li>
                                </ul>
                            </div>
                        </div>

                        <div class="overview-image">
                            <div class="image">
                                <img src="assets/img/features-image/3.png" alt="image">
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tabs_item">
                    <div class="features-overview">
                        <div class="overview-image">
                            <div class="image">
                                <img src="assets/img/features-image/4.png" alt="image">
                            </div>
                        </div>

                        <div class="overview-content">
                            <div class="content">
                                <span class="sub-title">Define Your Choices</span>
                                <h2>Branding</h2>
                                <p>We believe brand interaction is key in communication. Real innovations and a positive
                                    customer experience are the heart of successful communication. No fake products and
                                    services. The customer is king, their lives and needs are the inspiration.</p>

                                <ul class="features-list">
                                    <li><span><i class="flaticon-tick"></i> Open Source</span></li>
                                    <li><span><i class="flaticon-tick"></i> Optimal Choice</span></li>
                                    <li><span><i class="flaticon-tick"></i> High Security</span></li>
                                    <li><span><i class="flaticon-tick"></i> Great Advices</span></li>
                                    <li><span><i class="flaticon-tick"></i> Creative Layout</span></li>
                                    <li><span><i class="flaticon-tick"></i> Super Responsive</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tabs_item">
                    <div class="features-overview">
                        <div class="overview-content">
                            <div class="content">
                                <span class="sub-title">Define Your Choices</span>
                                <h2>Development</h2>
                                <p>We believe brand interaction is key in communication. Real innovations and a positive
                                    customer experience are the heart of successful communication. No fake products and
                                    services. The customer is king, their lives and needs are the inspiration.</p>

                                <ul class="features-list">
                                    <li><span><i class="flaticon-tick"></i> Open Source</span></li>
                                    <li><span><i class="flaticon-tick"></i> Optimal Choice</span></li>
                                    <li><span><i class="flaticon-tick"></i> High Security</span></li>
                                    <li><span><i class="flaticon-tick"></i> Great Advices</span></li>
                                    <li><span><i class="flaticon-tick"></i> Creative Layout</span></li>
                                    <li><span><i class="flaticon-tick"></i> Super Responsive</span></li>
                                </ul>
                            </div>
                        </div>

                        <div class="overview-image">
                            <div class="image">
                                <img src="assets/img/features-image/5.png" alt="image">
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tabs_item">
                    <div class="features-overview">
                        <div class="overview-image">
                            <div class="image">
                                <img src="assets/img/features-image/6.png" alt="image">
                            </div>
                        </div>

                        <div class="overview-content">
                            <div class="content">
                                <span class="sub-title">Define Your Choices</span>
                                <h2>Marketing</h2>
                                <p>We believe brand interaction is key in communication. Real innovations and a positive
                                    customer experience are the heart of successful communication. No fake products and
                                    services. The customer is king, their lives and needs are the inspiration.</p>

                                <ul class="features-list">
                                    <li><span><i class="flaticon-tick"></i> Open Source</span></li>
                                    <li><span><i class="flaticon-tick"></i> Optimal Choice</span></li>
                                    <li><span><i class="flaticon-tick"></i> High Security</span></li>
                                    <li><span><i class="flaticon-tick"></i> Great Advices</span></li>
                                    <li><span><i class="flaticon-tick"></i> Creative Layout</span></li>
                                    <li><span><i class="flaticon-tick"></i> Super Responsive</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="shape-img7"><img src="assets/img/shape/7.png" alt="image"></div>
    <div class="shape-img2"><img src="assets/img/shape/2.svg" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/3.svg" alt="image"></div>
    <div class="shape-img4"><img src="assets/img/shape/4.png" alt="image"></div>
</section>
<!-- End Features Area -->

<!-- Start Team Area -->
<section class="team-area ptb-100 pt-0">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Our Team</span>
            <h2>Meet Our Awesome Team Member</h2>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-team-box">
                    <div class="image">
                        <img src="assets/img/team-image/1.jpg" alt="team">

                        <div class="social">
                            <a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a>
                            <a href="#" target="_blank"><i class="fab fa-twitter"></i></a>
                            <a href="#" target="_blank"><i class="fab fa-instagram"></i></a>
                        </div>
                    </div>

                    <div class="content">
                        <h3>John Smith</h3>
                        <span>Web Developer</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-team-box">
                    <div class="image">
                        <img src="assets/img/team-image/2.jpg" alt="team">

                        <div class="social">
                            <a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a>
                            <a href="#" target="_blank"><i class="fab fa-twitter"></i></a>
                            <a href="#" target="_blank"><i class="fab fa-instagram"></i></a>
                        </div>
                    </div>

                    <div class="content">
                        <h3>Lucy Eva</h3>
                        <span>Manager</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                <div class="single-team-box">
                    <div class="image">
                        <img src="assets/img/team-image/3.jpg" alt="team">

                        <div class="social">
                            <a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a>
                            <a href="#" target="_blank"><i class="fab fa-twitter"></i></a>
                            <a href="#" target="_blank"><i class="fab fa-instagram"></i></a>
                        </div>
                    </div>

                    <div class="content">
                        <h3>Steven Smith</h3>
                        <span>Web Developer</span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="shape-img2"><img src="assets/img/shape/2.svg" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/3.svg" alt="image"></div>
    <div class="shape-img4"><img src="assets/img/shape/4.png" alt="image"></div>
    <div class="shape-img5"><img src="assets/img/shape/5.png" alt="image"></div>
    <div class="shape-img6"><img src="assets/img/shape/6.png" alt="image"></div>
    <div class="shape-img9"><img src="assets/img/shape/9.png" alt="image"></div>
    <div class="shape-img10"><img src="assets/img/shape/10.png" alt="image"></div>
</section>
<!-- End Team Area -->

<!-- Start Solution Area -->
<section class="solution-area ptb-100 extra-pb jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="solution-content">
                    <span class="sub-title">Watch Our Video</span>
                    <h2>Get Better Solution For Your Business</h2>
                    <p>No fake products and services. The customer is king, their lives and needs are the inspiration.
                    </p>

                    <a href="#" class="default-btn">Contact Us <span></span></a>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="solution-video">
                    <a href="https://www.youtube.com/watch?v=bk7McNUjWgw" class="video-btn popup-youtube"><i
                            class="flaticon-play-button"></i></a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Solution Area -->

<!-- Start Feedback Area -->
<section class="feedback-area ptb-100 bg-color">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Testimonials</span>
            <h2>Some Lovely Feedback From Our Clients</h2>
        </div>
    </div>

    <div class="feedback-slides owl-carousel owl-theme">
        <div class="single-feedback-item">
            <p>“We believe brand interaction is key in communication. Real innovations and a positive customer
                experience are the heart of successful communication. No fake products and services.”</p>

            <div class="client-info">
                <img src="assets/img/client-image/1.jpg" alt="image">

                <h3>Jason Roy</h3>
                <span>Manager</span>
            </div>
        </div>

        <div class="single-feedback-item">
            <p>“We believe brand interaction is key in communication. Real innovations and a positive customer
                experience are the heart of successful communication. No fake products and services.”</p>

            <div class="client-info">
                <img src="assets/img/client-image/2.jpg" alt="image">

                <h3>James Anderson</h3>
                <span>Web Developer</span>
            </div>
        </div>

        <div class="single-feedback-item">
            <p>“We believe brand interaction is key in communication. Real innovations and a positive customer
                experience are the heart of successful communication. No fake products and services.”</p>

            <div class="client-info">
                <img src="assets/img/client-image/3.jpg" alt="image">

                <h3>Sarah Taylor</h3>
                <span>Designer</span>
            </div>
        </div>

        <div class="single-feedback-item">
            <p>“We believe brand interaction is key in communication. Real innovations and a positive customer
                experience are the heart of successful communication. No fake products and services.”</p>

            <div class="client-info">
                <img src="assets/img/client-image/4.jpg" alt="image">

                <h3>Steven Smith</h3>
                <span>Manager</span>
            </div>
        </div>

        <div class="single-feedback-item">
            <p>“We believe brand interaction is key in communication. Real innovations and a positive customer
                experience are the heart of successful communication. No fake products and services.”</p>

            <div class="client-info">
                <img src="assets/img/client-image/5.jpg" alt="image">

                <h3>Tom Nessham</h3>
                <span>EnvyTheme</span>
            </div>
        </div>
    </div>
</section>
<!-- End Feedback Area -->

<!-- Start Pricing Area -->
<section class="pricing-area ptb-100 pt-0">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Pricing Plan</span>
            <h2>Choose Your Best Plan</h2>
            <p>Real innovations and a positive customer experience are the heart of successful communication. No fake
                products and services.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-pricing-box">
                    <div class="pricing-header">
                        <h3>Basic</h3>
                    </div>

                    <div class="price">
                        <sub>$</sub>
                        39.99
                        <sub>/mo</sub>
                    </div>

                    <ul class="price-features-list">
                        <li><i class="flaticon-tick"></i> 1 Projects</li>
                        <li><i class="flaticon-tick"></i> Email Support</li>
                        <li><i class="flaticon-tick"></i> Phone Support</li>
                        <li><i class="flaticon-tick"></i> Article Promotion</li>
                        <li><i class="flaticon-tick"></i> Editorial Services</li>
                        <li><i class="flaticon-close"></i> Profile Management</li>
                        <li><i class="flaticon-close"></i> Selection Support</li>
                    </ul>

                    <a href="#" class="get-started-btn">Get Started</a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-pricing-box red">
                    <div class="pricing-header">
                        <h3>Starter</h3>
                    </div>

                    <div class="price">
                        <sub>$</sub>
                        49.99
                        <sub>/mo</sub>
                    </div>

                    <ul class="price-features-list">
                        <li><i class="flaticon-tick"></i> 2 Projects</li>
                        <li><i class="flaticon-tick"></i> Email Support</li>
                        <li><i class="flaticon-tick"></i> Phone Support</li>
                        <li><i class="flaticon-tick"></i> Article Promotion</li>
                        <li><i class="flaticon-tick"></i> Editorial Services</li>
                        <li><i class="flaticon-tick"></i> Profile Management</li>
                        <li><i class="flaticon-close"></i> Selection Support</li>
                    </ul>

                    <a href="#" class="get-started-btn">Get Started</a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                <div class="single-pricing-box orange">
                    <div class="pricing-header">
                        <h3>Extended</h3>
                    </div>

                    <div class="price">
                        <sub>$</sub>
                        59.99
                        <sub>/mo</sub>
                    </div>

                    <ul class="price-features-list">
                        <li><i class="flaticon-tick"></i> 3 Projects</li>
                        <li><i class="flaticon-tick"></i> Email Support</li>
                        <li><i class="flaticon-tick"></i> Phone Support</li>
                        <li><i class="flaticon-tick"></i> Article Promotion</li>
                        <li><i class="flaticon-tick"></i> Editorial Services</li>
                        <li><i class="flaticon-tick"></i> Profile Management</li>
                        <li><i class="flaticon-tick"></i> Selection Support</li>
                    </ul>

                    <a href="#" class="get-started-btn">Get Started</a>
                </div>
            </div>
        </div>
    </div>

    <div class="shape-img2"><img src="assets/img/shape/2.svg" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/3.svg" alt="image"></div>
    <div class="shape-img4"><img src="assets/img/shape/4.png" alt="image"></div>
    <div class="shape-img5"><img src="assets/img/shape/5.png" alt="image"></div>
    <div class="shape-img6"><img src="assets/img/shape/6.png" alt="image"></div>
    <div class="shape-img9"><img src="assets/img/shape/9.png" alt="image"></div>
    <div class="shape-img10"><img src="assets/img/shape/10.png" alt="image"></div>
</section>
<!-- End Pricing Area -->

<!-- Start Partner Area -->
<section class="partner-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Partner</span>
            <h2>We partner with companies of all sizes, all around the world</h2>
        </div>

        <div class="row align-items-center">
            <div class="single-partner-item">
                <a href="#">
                    <img src="assets/img/partner-image/1.png" alt="image">
                    <img src="assets/img/partner-image/1.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#">
                    <img src="assets/img/partner-image/2.png" alt="image">
                    <img src="assets/img/partner-image/2.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#">
                    <img src="assets/img/partner-image/3.png" alt="image">
                    <img src="assets/img/partner-image/3.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#">
                    <img src="assets/img/partner-image/4.png" alt="image">
                    <img src="assets/img/partner-image/4.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#">
                    <img src="assets/img/partner-image/5.png" alt="image">
                    <img src="assets/img/partner-image/5.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#">
                    <img src="assets/img/partner-image/6.png" alt="image">
                    <img src="assets/img/partner-image/6.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#">
                    <img src="assets/img/partner-image/7.png" alt="image">
                    <img src="assets/img/partner-image/7.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#">
                    <img src="assets/img/partner-image/8.png" alt="image">
                    <img src="assets/img/partner-image/8.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#">
                    <img src="assets/img/partner-image/9.png" alt="image">
                    <img src="assets/img/partner-image/9.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#">
                    <img src="assets/img/partner-image/10.png" alt="image">
                    <img src="assets/img/partner-image/10.png" alt="image">
                </a>
            </div>
        </div>
    </div>

    <div class="shape-img2"><img src="assets/img/shape/2.svg" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/3.svg" alt="image"></div>
    <div class="shape-img4"><img src="assets/img/shape/4.png" alt="image"></div>
    <div class="shape-img5"><img src="assets/img/shape/5.png" alt="image"></div>
</section>
<!-- End Partner Area -->

<!-- Start Blog Area -->
<section class="blog-area ptb-100 bg-F4F7FC">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Latest News</span>
            <h2>Our Latest Articles And News For Our Clients</h2>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a href="#"><img src="assets/img/blog-image/1.jpg" alt="image"></a>
                    </div>

                    <div class="post-content">
                        <div class="post-meta">
                            <ul>
                                <li>By: <a href="#">Sarah Taylor</a></li>
                                <li>June 24, 2019</li>
                            </ul>
                        </div>

                        <h3><a href="#">How To Boost Your Digital Marketing Agency</a></h3>
                        <p>Lorem ipsum dolor sit amet, constetur adipiscing elit, sed do eiusmod tempor incididunt.</p>

                        <a href="#" class="read-more-btn">Read More <i class="flaticon-right-arrow"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a href="#"><img src="assets/img/blog-image/2.jpg" alt="image"></a>
                    </div>

                    <div class="post-content">
                        <div class="post-meta">
                            <ul>
                                <li>By: <a href="#">James Anderson</a></li>
                                <li>June 26, 2019</li>
                            </ul>
                        </div>

                        <h3><a href="#">The Rise Of Smarketing And Why You Need It</a></h3>
                        <p>Lorem ipsum dolor sit amet, constetur adipiscing elit, sed do eiusmod tempor incididunt.</p>

                        <a href="#" class="read-more-btn">Read More <i class="flaticon-right-arrow"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a href="#"><img src="assets/img/blog-image/3.jpg" alt="image"></a>
                    </div>

                    <div class="post-content">
                        <div class="post-meta">
                            <ul>
                                <li>By: <a href="#">Steven Smith</a></li>
                                <li>June 25, 2019</li>
                            </ul>
                        </div>

                        <h3><a href="#">How To Use Music To Boost Your Business</a></h3>
                        <p>Lorem ipsum dolor sit amet, constetur adipiscing elit, sed do eiusmod tempor incididunt.</p>

                        <a href="#" class="read-more-btn">Read More <i class="flaticon-right-arrow"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="shape-img2"><img src="assets/img/shape/2.svg" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/3.svg" alt="image"></div>
</section>
<!-- End Blog Area -->

<!-- Start Subscribe Area -->
<section class="subscribe-area ptb-100 pt-0 bg-F4F7FC">
    <div class="container">
        <div class="subscribe-inner-area jarallax" data-jarallax='{"speed": 0.3}'>
            <div class="subscribe-content">
                <span class="sub-title">Get Started Instantly!</span>
                <h2>Get only new update from this newsletter</h2>

                <form class="newsletter-form" data-toggle="validator">
                    <input type="email" class="input-newsletter" placeholder="Enter your email" name="EMAIL" required
                        autocomplete="off">

                    <button type="submit">Subscribe</button>
                    <div id="validator-newsletter" class="form-result"></div>
                </form>
            </div>
        </div>
    </div>

    <div class="shape-img2"><img src="assets/img/shape/2.svg" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/3.svg" alt="image"></div>
    <div class="shape-img4"><img src="assets/img/shape/4.png" alt="image"></div>
    <div class="shape-img5"><img src="assets/img/shape/5.png" alt="image"></div>
    <div class="shape-img6"><img src="assets/img/shape/6.png" alt="image"></div>
</section>
<!-- End Subscribe Area -->
