<!-- Start Main Banner Area -->
<div class="hero-banner services">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-12">
                        <div class="hero-banner-content">
                            <h1>Product Prototyping</h1>
                            <a href="javascript:void(Tawk_API.popup())" class="default-btn">Get Estimate<span></span></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Main Banner Area -->

<!-- Start Details Section Area -->
<div class="details-section-area">
    <div class="container-fluid">
        <div class="row dark-bg">
            <div class="row-padding">
                <div class="col-12 col-md-7 img-holder">
                    <div class="section-area-img img1">
                    </div>
                </div>
                <div class="col-12 col-md-5 text-holder">
                    <div class="section-area-text">
                        <div class="details-title">User Interface</div>
                        <div class="details-text">
                            <div class="mb-25">
                            User interface is all about web pages layout and what they look like. We know that
                            appearance is one of the main aspects for having more clients, so we work hard in this direction.
                            </div>
                            <div class="mb-25">
                                <i class="flaticon-tick"></i>
                                Constant communication with you
                            </div>
                            <div class="mb-25">
                                <i class="flaticon-tick"></i>
                                Consistent design
                            </div>
                            <div class="mb-25">
                                <i class="flaticon-tick"></i>
                                Branding
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row gray-bg">
            <div class="row-padding">
                <div class="col-12 col-md-5 text-holder">
                    <div class="section-area-text">
                        <div class="details-title">User Experience</div>
                        <div class="details-text">
                            <div class="mb-25">
                            We create intuitive UX design for effective user usability. We know that creating an easy to
                            understand visual interface contributes to more business customers. And we also know how
                            repulsing a bad UX can be. To create our prototypes, we use software solutions like Figma.
                            Our design solutions are:
                            </div>
                            <div class="mb-25">
                                <i class="flaticon-tick"></i>
                                User-centered
                            </div>
                            <div class="mb-25">
                                <i class="flaticon-tick"></i>
                                Desirable
                            </div>
                            <div class="mb-25">
                                <i class="flaticon-tick"></i>
                                Usable
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-7 img-holder">
                    <div class="section-area-img img2">
                    </div>
                </div>
            </div>
        </div>
        <div class="row dark-bg">
            <div class="row-padding">
                <div class="col-12 col-md-7 img-holder">
                    <div class="section-area-img img3">
                    </div>
                </div>
                <div class="col-12 col-md-5 text-holder">
                    <div class="section-area-text">
                        <div class="details-title">How we do it</div>
                        <div class="details-text">
                            <div class="mb-25">
                            To get a design both functional AND one you truly like, we follow a 3-step process:
                            </div>
                            <div class="mb-25">
                                <i class="flaticon-tick"></i>
                                Wireframe - Rough design with all functionalities included (prototype)
                            </div>
                            <div class="mb-25">
                                <i class="flaticon-tick"></i>
                                Low fidelity - Good-looking design, pretty close to the actual, but only if approved by you
                            </div>
                            <div class="mb-25">
                                <i class="flaticon-tick"></i>
                                High fidelity - Pixel-perfect design of what you have approved from the previous step
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Details Section Area -->

<div class="partner-area ptb-100">
    <div class="container">
        <div class="partner-slides owl-carousel owl-theme">
            <div class="single-partner-item">
                <a href="#">
                    <img src="assets/img/partner-image/sketch.svg" alt="image" title="Sketch">
                    <img src="assets/img/partner-image/sketch.svg" alt="image" title="Sketch">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#">
                    <img src="assets/img/partner-image/figma.svg" alt="image" title="Figma">
                    <img src="assets/img/partner-image/figma.svg" alt="image" title="Figma">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#">
                    <img src="assets/img/partner-image/ai.svg" alt="image" title="Illustrator">
                    <img src="assets/img/partner-image/ai.svg" alt="image" title="Illustrator">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#">
                    <img src="assets/img/partner-image/ps.svg" alt="image" title="Photoshop">
                    <img src="assets/img/partner-image/ps.svg" alt="image" title="Photoshop">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#">
                    <img src="assets/img/partner-image/xd.svg" alt="image" title="Adobe XD">
                    <img src="assets/img/partner-image/xd.svg" alt="image" title="Adobe XD">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#">
                    <img src="assets/img/partner-image/marvel.png" alt="image" title="Marvel">
                    <img src="assets/img/partner-image/marvel.png" alt="image" title="Marvel">
                </a>
            </div>
        </div>
    </div>
</div>