<!-- Start Main Banner Area -->
<div class="hero-banner services">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-12">
                        <div class="hero-banner-content">
                            <h1>Our Team</h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Main Banner Area -->

<div class="details-section-area">
    <div class="container-fluid">
        <div class="row dark-bg">
            <div class="row-padding">
                <div class="col-12 col-md-7 img-holder">
                    <div class="section-area-img img1">
                    </div>
                </div>
                <div class="col-12 col-md-5 text-holder">
                    <div class="section-area-text">
                        <div class="details-title">Who are we</div>
                        <div class="details-text">
                            <div class="mb-25">
                            We are a small but cohesive team of positive people! Programming is our hobby and we always 
                            develop our applications with passion. We always do our best and our goal is to have only 
                            satisfied customers!
                            </div>
                            <div class="mb-25">
                                <i class="flaticon-tick"></i>
                                Collaborative
                            </div>
                            <div class="mb-25">
                                <i class="flaticon-tick"></i>
                                Determined
                            </div>
                            <div class="mb-25">
                                <i class="flaticon-tick"></i>
                                Passionate
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Start Team Area -->
<section class="team-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="single-team-box">
                    <div class="image">
                        <img src="assets/img/team-image/Dobri.jpg" alt="team">

                        <div class="social">
                            <i class="flaticon-tick"></i>
                            Senior Developer for 20 years<br>
                            <i class="flaticon-tick"></i>
                            Devops, Sys Admin<br>
                            <i class="flaticon-tick"></i>
                            Back-End Python Developer<br>
                            <i class="flaticon-tick"></i>
                            Data Scientist
                            <!-- <a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a>
                            <a href="#" target="_blank"><i class="fab fa-twitter"></i></a>
                            <a href="#" target="_blank"><i class="fab fa-instagram"></i></a> -->
                        </div>
                    </div>

                    <div class="content">
                        <h3>Dobri Stoilov</h3>
                        <span>Software Architect</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="single-team-box">
                    <div class="image">
                        <img src="assets/img/team-image/Silvena2.jpg" alt="team">

                        <div class="social">
                            <i class="flaticon-tick"></i>
                            Senior Developer for 8 years<br>
                            <i class="flaticon-tick"></i>
                            Front-End Developer - Vue, Angular, React<br>
                            <i class="flaticon-tick"></i>
                            Back-End Developer - Python, PHP<br>
                            <i class="flaticon-tick"></i>
                            Data Scientist
                            <!-- <a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a>
                            <a href="#" target="_blank"><i class="fab fa-twitter"></i></a>
                            <a href="#" target="_blank"><i class="fab fa-instagram"></i></a> -->
                        </div>
                    </div>

                    <div class="content">
                        <h3>Silvena Shivacheva</h3>
                        <span>Data Scientist</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="single-team-box">
                    <div class="image">
                        <img src="assets/img/team-image/Vassil.jpg" alt="team">

                        <div class="social">
                            <i class="flaticon-tick"></i>
                            Senior Developer for 5 years<br>
                            <i class="flaticon-tick"></i>
                            Front-End Developer - React, Angular, Vue<br>
                            <i class="flaticon-tick"></i>
                            Back-End Developer - NodeJS<br>
                            <!-- <a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a>
                            <a href="#" target="_blank"><i class="fab fa-twitter"></i></a>
                            <a href="#" target="_blank"><i class="fab fa-instagram"></i></a> -->
                        </div>
                    </div>

                    <div class="content">
                        <h3>Vassil Nikolov</h3>
                        <span>Full-Stack Developer</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="single-team-box">
                    <div class="image">
                        <img src="assets/img/team-image/Emma.jpg" alt="team">

                        <div class="social">
                            <i class="flaticon-tick"></i>
                            UX Designer with Painting background<br>
                            <i class="flaticon-tick"></i>
                            Adobe Products - Photoshop, Illustrator, XD<br>
                            <i class="flaticon-tick"></i>
                            Design Platforms - Figma, Sketch<br>
                            <!-- <a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a>
                            <a href="#" target="_blank"><i class="fab fa-twitter"></i></a>
                            <a href="#" target="_blank"><i class="fab fa-instagram"></i></a> -->
                        </div>
                    </div>

                    <div class="content">
                        <h3>Emma Ivanova</h3>
                        <span>UI/UX Designer</span>
                    </div>
                </div>
            </div>

        </div>
    </div>

    <div class="shape-img2"><img src="assets/img/shape/2.svg" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/3.svg" alt="image"></div>
    <div class="shape-img4"><img src="assets/img/shape/4.png" alt="image"></div>
    <div class="shape-img5"><img src="assets/img/shape/5.png" alt="image"></div>
    <div class="shape-img6"><img src="assets/img/shape/6.png" alt="image"></div>
    <div class="shape-img9"><img src="assets/img/shape/9.png" alt="image"></div>
    <div class="shape-img10"><img src="assets/img/shape/10.png" alt="image"></div>
</section>
<!-- End Team Area -->
