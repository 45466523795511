<!-- Start Main Banner Area -->
<div class="hero-banner services">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-12">
                        <div class="hero-banner-content">
                            <h1>Data Science</h1>
                            <a href="javascript:void(Tawk_API.popup())" class="default-btn">Get Estimate<span></span></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Main Banner Area -->

<!-- Start Details Section Area -->
<div class="details-section-area">
    <div class="container-fluid">
        <div class="row dark-bg">
            <div class="row-padding">
                <div class="col-12 col-md-7 img-holder">
                    <div class="section-area-img img1">
                    </div>
                </div>
                <div class="col-12 col-md-5 text-holder">
                    <div class="section-area-text">
                        <div class="details-title">Tech Stack</div>
                        <div class="details-text">
                            <div class="mb-25">
                            Python is proven to be the best language for Data Science. We use it along with the 5
                            inseparable ML libraries - Numpy, Scipy, Matplotlib, Pands and Sklearn. We provide graphics, 
                            charts and plots for data visualization.
                            </div>
                            <div class="mb-25">
                                <i class="flaticon-tick"></i>
                                Study big sets of data
                            </div>
                            <div class="mb-25">
                                <i class="flaticon-tick"></i>
                                Performing AI algoritms
                            </div>
                            <div class="mb-25">
                                <i class="flaticon-tick"></i>
                                Self-teaching software
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row gray-bg">
            <div class="row-padding">
                <div class="col-12 col-md-5 text-holder">
                    <div class="section-area-text">
                        <div class="details-title">In-depth ML</div>
                        <div class="details-text">
                            <div class="mb-25">
                            We work using different models depending on the case we have. We always test/train our code
                            so we can be sure that the results we provide are meaningful and can be trusted. Some of the
                            algorithms we use are:
                            </div>
                            <div class="mb-25">
                                <i class="flaticon-tick"></i>
                                Kaplan-Meier estimator for survival analysis
                            </div>
                            <div class="mb-25">
                                <i class="flaticon-tick"></i>
                                Agglomerative and hierarchical clustering
                            </div>
                            <div class="mb-25">
                                <i class="flaticon-tick"></i>
                                Binary and multiclass classifications
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-7 img-holder">
                    <div class="section-area-img img2">
                    </div>
                </div>
            </div>
        </div>
        <div class="row dark-bg">
            <div class="row-padding">
                <div class="col-12 col-md-7 img-holder">
                    <div class="section-area-img img3">
                    </div>
                </div>
                <div class="col-12 col-md-5 text-holder">
                    <div class="section-area-text">
                        <div class="details-title">Lots of applications</div>
                        <div class="details-text">
                            <div class="mb-25">
                            Data Science is getting more and more popular and used amongst companies. It facilitates
                            decision-making processes, predicts trends and saves time and money to business owners.
                            Some widely used applications are:
                            </div>
                            <div class="mb-25">
                                <i class="flaticon-tick"></i>
                                Predict machine failure before it occurs
                            </div>
                            <div class="mb-25">
                                <i class="flaticon-tick"></i>
                                Create perfect pricing strategies for revenue growth
                            </div>
                            <div class="mb-25">
                                <i class="flaticon-tick"></i>
                                Market researches for attracting more customers
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Details Section Area -->

<div class="partner-area ptb-100">
    <div class="container">
        <div class="partner-slides owl-carousel owl-theme">
            <div class="single-partner-item">
                <a href="#">
                    <img src="assets/img/partner-image/python.png" alt="image" title="Python">
                    <img src="assets/img/partner-image/python.png" alt="image" title="Python">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#">
                    <img src="assets/img/partner-image/numpy.svg" alt="image" title="Numpy">
                    <img src="assets/img/partner-image/numpy.svg" alt="image" title="Numpy">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#">
                    <img src="assets/img/partner-image/scipy.png" alt="image" title="SciPy">
                    <img src="assets/img/partner-image/scipy.png" alt="image" title="SciPy">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#">
                    <img src="assets/img/partner-image/scikit.svg" alt="image" title="Scikit Learn">
                    <img src="assets/img/partner-image/scikit.svg" alt="image" title="Scikit Learn">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#">
                    <img src="assets/img/partner-image/pandas.svg" alt="image" title="Pandas">
                    <img src="assets/img/partner-image/pandas.svg" alt="image" title="Pandas">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#">
                    <img src="assets/img/partner-image/matplotlib.svg" alt="image" title="MatPlotLib">
                    <img src="assets/img/partner-image/matplotlib.svg" alt="image" title="MatPlotLib">
                </a>
            </div>
        </div>
    </div>
</div>