<!-- Start Main Banner Area -->
<div class="hero-banner services">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-12">
                        <div class="hero-banner-content">
                            <h1>Contact</h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Contact Area -->
<section class="contact-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="contact-info-box">
                    <div class="icon">
                        <i class="flaticon-email"></i>
                    </div>

                    <h3>Email</h3>
                    <p><a href="mailto:office@abovedev.com">office@abovedev.com</a></p>
                    <p><a href="mailto:hr@abovedev.com">hr@abovedev.com</a></p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="contact-info-box">
                    <div class="icon">
                        <i class="flaticon-marker"></i>
                    </div>

                    <h3>Location</h3>
                    <p><a href="https://www.google.com/maps/place/bul.+%22Hristo+Smirnenski%22+4,+1164+g.k.+Lozenets,+Sofia/@42.6851291,23.328152,17z/data=!3m1!4b1!4m5!3m4!1s0x40aa850a1cc684d5:0x2999ac7b54c876a8!8m2!3d42.6851291!4d23.3303407" target="_blank">
                        bul. Hristo Smirnenski 4<br>
                        Lozenets, Sofia, Bulgaria
                    </a></p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                <div class="contact-info-box">
                    <div class="icon">
                        <i class="flaticon-phone-call"></i>
                    </div>

                    <h3>Phone</h3>
                    <p><a href="tel:00359883472597">+359 883 472 597</a></p>
                    <p><a href="tel:00359879366777">+359 879 366 777</a></p>
                </div>
            </div>
        </div>
        
        <div class="section-title">
            <span class="sub-title">Contact Us</span>
            <h2>Drop us a Message</h2>
            <p>If you have questions of any type, do not hesitate to contact us.</p>
        </div>

        <div class="row align-items-center" #contactForm>
            <!-- <div class="col-lg-4 col-md-4">
                <div class="contact-image">
                    <img src="assets/img/mail.svg" alt="image">
                </div>
            </div> -->

            <div class="col-lg-12 col-md-12">
                <div class="contact-form">
                    <form [formGroup]="FormData" (ngSubmit)="onSubmit(FormData.value)">
                        <div class="row">
                            <div class="col-lg-6 col-md-12">
                                <div class="form-group">
                                    <input type="text" formControlName="Name" name="name" id="name" class="form-control" required data-error="Please enter your name" placeholder="Name*">
                                    <div class="help-block with-errors"></div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-12">
                                <div class="form-group">
                                    <input type="email" formControlName="Email" name="email" id="email" class="form-control" required data-error="Please enter your email" placeholder="Email*">
                                    <div class="help-block with-errors"></div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-12">
                                <div class="form-group">
                                    <input type="text" formControlName="Phone" name="phone_number" id="phone_number" required data-error="Please enter your number" class="form-control" placeholder="Phone*">
                                    <div class="help-block with-errors"></div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-12">
                                <div class="form-group">
                                    <input type="text" formControlName="Subject" name="msg_subject" id="msg_subject" class="form-control" required data-error="Please enter your subject" placeholder="Subject*">
                                    <div class="help-block with-errors"></div>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <textarea formControlName="Msg" name="message" class="form-control" id="message" cols="30" rows="5" required data-error="Write your message" placeholder="Your Message*"></textarea>
                                    <div class="help-block with-errors"></div>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <button type="submit" class="default-btn" [disabled]="!FormData.valid">Send Message <span></span></button>
                                <div id="msgSubmit" class="h3 text-center hidden"></div>
                                <div class="clearfix"></div>
                            </div>

                            <div class="alerts">
                                <div *ngIf="success" class="alert alert-success" role="alert">
                                    Your message was successfully sent!
                                </div>
                                <div *ngIf="error" class="alert alert-danger" role="alert">
                                    An error occurred while sending your message, please try again later. Sorry for the inconvenience!
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Contact Area -->